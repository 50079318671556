import React from "react";
import style from "./landingPage.module.css";
import CustomLottie from "../../lotties/CustomLottie";
import digitalMediaAnimation from "../../lotties/digitalMedia.json";

export default function WhatWeDoSection() {
  return (
    <div
      className={
        "relative flex flex-col md:justify-center md:py-24 lg:flex-row lg:justify-between items-center justify-start sm:flex-col p-11  " +
        style.whatWeDoSection
      }
    >
      <div className="relative lg:w-5/12 xl:w-6/12">
        <h1
          className={
            "text-4xl sm:text-6xl md:text-7xl  xl:text-7xl font-bold shadow-lg " +
            style.textShadowDark
          }
        >
          What We Do
        </h1>
        <h1
          className={
            "absolute text-4xl xl:text-6xl top-4  md:top-11 lg:top-11 xl:top-8 text-white xs:text-4xl sm:text-7xl sm:top-7 md:text-7xl  lg:text-7xl"
          }
        >
          What We Do
        </h1>
        <p className="text-base md:text-xl md:mt-16 lg:text-xl xl:text-2xl xl:text-zinc-400 md:leading-8 xl:leading-10 font-thin mt-11  ">
          IEEE AAST Alex SB is one of the most outstanding IEEE student branches
          not only across the Egypt section, but also the 8th region, with the
          vision of offering students a variety of events including sessions,
          workshops, webinars, field trips, and hackathons. All related majorly
          to the fields of engineering, computer science, and soft skills needed
          to accomplish fully skilled engineers and benefit a wider range of
          students.
        </p>
      </div>
      <div className=" lg:w-5/12">
        <CustomLottie animation={digitalMediaAnimation} />
      </div>
    </div>
  );
}
