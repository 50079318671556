import * as BiIcons from "react-icons/bi";
import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as ImIcons from "react-icons/im";
import * as HiIcons from "react-icons/hi";
import * as BsIcons from "react-icons/bs";

const committees = [
  {
    name: "IT",
    image: <BiIcons.BiCodeAlt />,
    desc: "Web & App Development",
  },
  {
    name: "Technical",
    image: <FaIcons.FaRobot />,
    desc: "Robotics & Electronics",
  },
  {
    name: "Media",
    image: <MdIcons.MdVideoCameraBack />,
    desc: "Photo Editing – Video Editing – Photography",
  },
  {
    name: "Registration",
    image: <HiIcons.HiOutlineDocumentReport />,
    desc: "Data Analysis & Event Registration",
  },
  {
    name: "Marketing",
    image: <HiIcons.HiOutlineSpeakerphone />,
    desc: "Digital & Offline Marketing",
  },
  {
    name: "Documentation",
    image: <BsIcons.BsPencilSquare />,
    desc: "Content Creation",
  },
  {
    name: "Operations",
    image: <ImIcons.ImUsers />,
    desc: "Logistics & Organization",
  },
];
export default committees;
