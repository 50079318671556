import axios from "axios";
import React, { useState, Fragment } from "react";
import tw from "tailwind-styled-components";
export const DecisionButton = ({ applicant, getApplicants }) => {
  const [approved, setApproved] = useState(applicant.isApproved);
  const handleClick = async () => {
    try {
      setApproved(!approved);
      await axios.put(
        `https://ieeeaast-backend-ieeeaast.vercel.app/api/volunteers/updateVolunteer/${applicant.id}`,
        {
          ...applicant,
          isApproved: !approved,
        }
      );
      getApplicants();
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Fragment>
      <button
        onClick={() => handleClick()}
        className={`${
          approved
            ? "bg-red-500 hover:bg-red-400"
            : "bg-green-500 hover:bg-green-400 "
        }  rounded-lg py-2 px-2 text-xs hover:shadow-lg transistion ease-in`}
      >
        {approved ? "Reject" : "Approve"}
      </button>
    </Fragment>
  );
};
