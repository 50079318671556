import React, { useContext } from "react";

import FormDataContext from "../context/FormContext";
import FormStep1 from "../components/form/FormStep1";
import FormStep2 from "../components/form/FormStep2";
import FormStep3 from "../components/form/FormStep3";
import FormStep4 from "../components/form/FormStep4";
import FormStep5 from "../components/form/FormStep5";
import FormStep6 from "../components/form/FormStep6";
import Stepper from "../components/form/Stepper";
import * as IoIcons from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import ClosingRecruitmentPopup from "../components/form/ClosingRecruitmentPopup";

function Membership() {
  let { step } = useContext(FormDataContext);
  const navigate = useNavigate();
  let steps = () => {
    switch (step) {
      case 1:
        return <FormStep1 />;
      case 2:
        return <FormStep2 />;
      case 3:
        return <FormStep3 />;
      case 4:
        return <FormStep4 />;
      case 5:
        return <FormStep5 />;
      case 6:
        return <FormStep6 />;
      default:
        return;
    }
  };

  return (
    <div className="bg-[#121316] text-white max-h-screen flex justify-center overflow-y-scroll overflow-x-hidden">
      <ClosingRecruitmentPopup />
      <div className="py-16 w-10/12 min-h-screen">
        <div className="mb-14 mt-14">
          <button
            className="text-4xl mb-5 text-white border rounded-full hover:bg-white hover:text-black transition ease-in"
            onClick={() => navigate(-1)}
          >
            <IoIcons.IoArrowBack />
          </button>
          <h1 className="text-white text-3xl">Recruitment</h1>
          <div className="text-neutral-400 font-thin">
            Follow the simple 6 steps to complete the form.
          </div>
        </div>
        <div className="flex gap-8 flex-wrap lg:flex-nowrap">
          <div className="grow">
            <div className="sticky top-10">
              <Stepper />
            </div>
          </div>
          <div className="w-full">
            <div
              className={
                "px-1 py-5 text-lg text-[#fbbebe] rounded-md text-center bg-[#401414] mb-10"
              }
            >
              We’re no longer accepting applicants, you can join again next year
            </div>
            <div className="text-neutral-400 font-thin text-s">
              Step {step} of 6
            </div>
            {steps()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Membership;
