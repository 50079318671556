const headers = [
  {
    label: "Full Name",
    key: "fullName",
  },
  {
    label: "Email",
    key: "email",
  },
  {
    label: "GPA",
    key: "gpa",
  },
  {
    label: "Term",
    key: "semester",
  },
  {
    label: "Address",
    key: "address",
  },
  {
    label: "Phone Number",
    key: "phoneNumber",
  },
  {
    label: "Faculty",
    key: "faculty",
  },
  {
    label: "Department",
    key: "department",
  },
  {
    label: "Did you join any civil work organization before?",
    key: "civilWork",
  },
  {
    label: "Skills",
    key: "skills",
  },
  {
    label: "Hobbies",
    key: "hobbies",
  },
  {
    label: "How would you describe yourself?",
    key: "describeYourself",
  },
  {
    label: "What achievements have you accomplished throughout your life?",
    key: "achievemenets",
  },
  {
    label: "What does IEEE abbreviation stand for?",
    key: "ieeeLettersAbv",
  },
  {
    label: "What do you know about IEEE?",
    key: "whatDoYouKnowAboutIeee",
  },
  {
    label: "Why do you want to join IEEE?",
    key: "whyDoYouWantToJoinIEEE",
  },
  {
    label: "Did you attend any IEEE events before?",
    key: "didAttendEvents",
  },
  {
    label: "Can you please provide the events' names that you attended?",
    key: "eventNames",
  },
  {
    label: "Primary Committee",
    key: "primaryCommittee",
  },
  {
    label: "First secondary committee",
    key: "secondaryCommitee1",
  },
  {
    label: "Second secondary committee",
    key: "secondaryCommittee2",
  },
  {
    label: "IT Experience",
    key: "ITExperience",
  },
  {
    label: "Technical Experience",
    key: "technicalExperience",
  },
  {
    label: "Media Experience",
    key: "mediaExperience",
  },
  {
    label: "Marketing Experience",
    key: "marketingExperience",
  },
  {
    label: "Documentation Experience",
    key: "documentationExperience",
  },
  {
    label: "Registration Experience",
    key: "registrationExperience",
  },
  {
    label: "Operations Experience",
    key: "operationExperience",
  },
  {
    label: "How many hours could you commit to IEEE per week?",
    key: "commitmentHours",
  },
  {
    label:
      "Would you rather be your own boss? How do you feel to taking orders or tasks?",
    key: "ratherBeYourOwnBoss",
  },
  {
    label: "What sets you apart from other candidates?",
    key: "setsYouApartFromCandidates",
  },
  {
    label: "How did you know about the recruitment?",
    key: "howDidYouKnowAboutRecruitment",
  },
  {
    label: "What is the volunteer's name?",
    key: "volunteersName",
  },
  {
    label: "What is your friend's name?",
    key: "friendsName",
  },
  {
    label:
      "Can you please explain more about how you knew about the recruitment?",
    key: "otherName",
  },
  {
    label: "IS APPROVED?",
    key: "isApproved",
  },
];
export default headers;
