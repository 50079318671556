import React from "react";
import * as AiIcons from "react-icons/ai";
import style from "../../pages/About/about.module.css";
export default function BoardCard({
  name,
  position,
  image,
  email,
  linkedInUrl,
}) {
  return (
    <div
      className={`${style.card} ${position === "Counselor" ? "m-auto" : ""}`}
    >
      <div className={`${style.cardHeader} w-auto`}>
        <img
          src={image}
          alt="boardmember"
          className="absolute top-11 rounded-full w-44 h-44 mx-auto border-4"
        />
      </div>
      <div className={style.card.title}>
        <h1 className="text-white font-bold mt-20">{name}</h1>
      </div>
      <div className={style.cardBody}>
        <h4 className="text-white">{position}</h4>
      </div>
      <div
        className={`${
          position === "Counselor"
            ? "hidden"
            : `${style.cardFooter} flex text-white text-3xl justify-around mt-7`
        }`}
      >
        <a href={email ? `mailto:${email}` : ""}>
          <AiIcons.AiFillMail className={style.icon} />
        </a>
        <a
          target={"_blank"}
          href={linkedInUrl ? linkedInUrl : ""}
          rel="noreferrer"
        >
          <AiIcons.AiFillLinkedin className={style.icon} />
        </a>
      </div>
    </div>
  );
}
