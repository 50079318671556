import React from "react";
import { PencilIcon } from "@heroicons/react/24/solid";

const Abouteditor = () => {
  return (
    <div className="  mt-4 flex flex-col lg:pl-[3rem]   p-4 max-w-fit max-h-fit">
      <div className="flex justify-between items-center mb-5">
        <h4 className="mr-[169px] min-[375px]:mr-[231px] text-white">About</h4>
        <PencilIcon className="lg:w-5 w-4 md:w-[1.5rem] text-[#6A6A6A] xl:mr-8"></PencilIcon>
      </div>
      <div>
        <p className="text-[13px] font-light">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iste
          corrupti possimus eaque maiores iure ea velit architecto
          necessitatibus aspernatur ullam Lorem ipsum dolor sit amet consectetur
          adipisicing elit. Accusantium libero quo dolore voluptatem illum totam
          maiores nobis optio repellat, consectetur at, iste molestiae
          consequuntur nihil! Similique temporibus harum repudiandae totam!
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Veritatis,
          cum nisi quia dignissimos laboriosam consequatur, numquam nemo
          provident recusandae dolorem laborum saepe culpa optio maiores
          architecto, exercitationem in deserunt facere. Lorem ipsum dolor sit
          amet consectetur adipisicing elit. Quos alias dolorem illo harum
          provident recusandae blanditiis optio aliquid facilis exercitationem
          quae nulla cumque nesciunt necessitatibus excepturi, mollitia, esse
          est inventore?
        </p>{" "}
      </div>
    </div>
  );
};

export default Abouteditor;
