import React from "react";
import Scene from "../../components/Scene";
import { a } from "@react-spring/web";
import { useSpring } from "@react-spring/core";
import style from "./landingPage.module.css";
import { useState } from "react";
import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";

export default function HeaderSection() {
  const [textColor, setTextColor] = useState("#121316");
  const [{ background, fill }, set] = useSpring(
    { background: "#fff", fill: "#282c34" },
    []
  );

  const handler = (color) => {
    setTextColor(color);
  };
  return (
    <section
      className={
        "flex flex-col items-center justify-center flex-wrap w-screen relative overflow-hidden lg:overflow-hidden  sm:overflow-x-hidden md:overflow-hidden xl:overflow-x-hidden "
      }
    >
      <a.main
        style={{ background }}
        className={
          " flex-col sm:justify-center lg:flex-row xs:flex-col md:flex-col flex"
        }
      >
        <div
          className={`flex flex-col  px-4 sm:pt-32 md:py-56 lg:pr-32 xl:px-11 justify-center  py-32 lg:py-28 xs:p-11 xs:mt-32 xl:w-11/12 xl:p-3  sm:text-base sm:p-11 sm:text-left   ${style.title} `}
        >
          <h1
            className="text-6xl sm:text-7xl lg:text-8xl font-bold "
            style={{ color: textColor }}
          >
            .<span className={"text-blue-700"}>.</span>
          </h1>
          <h1
            className="text-4xl sm:text-6xl md:text-7xl lg:text-6xl xl:text-7xl font-bold xs:text-5xl  "
            style={{ color: textColor }}
          >
            we <span className={"text-blue-700"}>think</span>. research.
          </h1>
          <h1
            className=" text-4xl sm:text-6xl md:text-7xl lg:text-7xl xl:text-7xl top-36 font-bold mb-5"
            style={{ color: textColor }}
          >
            build. <span className={"text-blue-700"}>inspire</span>.
          </h1>
          <p
            className="text-xl sm:text-2xl sm:mt-6 top-36 font-thin text-left"
            style={{ color: textColor }}
          >
            IEEE AAST Alexandria Student Branch
          </p>
          <h1
            className={
              " absolute  font-bold opacity-10 hidden sm:block top-6 -left-11  " +
              style.IEEEShadowHeaderBottom
            }
          >
            IEEE
          </h1>
          <h1
            className={
              " absolute  font-bold opacity-10 hidden sm:block bottom-24 -right-11 " +
              style.IEEEShadowHeaderTop
            }
          >
            IEEE
          </h1>
          {/* <motion.button
            onClick={() => navigate("/recruitment")}
            className={`${style.joinUsButton} px-3 text-base w-11/12 md:text-2xl lg:text-base  lg:w-8/12  py-3 xs:w-full sm:text-base sm:w-5/12 sm:px-3 sm:py-2 md:px-3 md:py-3 lg:px-3`}
          >
            Join Us!
          </motion.button> */}
        </div>
        <Canvas dpr={[1, 2]}>
          <Scene setBg={set} setColor={handler} />;
          <OrbitControls
            enablePan={false}
            enableZoom={false}
            maxPolarAngle={Math.PI / 2}
            minPolarAngle={Math.PI / 2}
          />
        </Canvas>
      </a.main>
    </section>
  );
}
