import React from "react";
import SidebarOpen from "./SidebarOpen";
export default function Sidebar(props) {

    return (
        <div className="">
            <div className={props.close ? "w-[20%] h-full fixed transition-all duration-500 -left-96 top-0 bg-[#121315]" : "w-80 h-full fixed transition-all duration-500 -left-96 lg:left-0  top-0 bg-[#121315]"}>
                <div
                    className="z-1 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-60 h-full"
                    aria-label="Sidebar"
                >
                    <SidebarOpen
                    fn={props.fn}
                    close={props.close}
                    profilepic={props.profilepic}
                    />
                </div>
            </div>
        </div>
    );
}
