import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import tw from "tailwind-styled-components";
import AuthServices from "../AuthServices";
export default function AdminLogin() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [status, setStatus] = useState({ success: false, message: "" });
  const navigate = useNavigate();
  const login = async (e) => {
    e.preventDefault();
    try {
      setStatus({
        message: "",
        success: false,
      });
      const data = await AuthServices.login({ email, password });
      console.log(data);
      if (data.role !== "admin") {
        setStatus({
          success: false,
          message: "Access forbidden!",
        });
        return;
      } else navigate("/recruitment_entries");
    } catch (error) {
      console.log(error);
      setStatus({
        success: false,
        message: "Email or password is incorrect",
      });
    }
  };
  return (
    <Wrapper>
      <Form onSubmit={login}>
        <Title>Admin Login</Title>
        <Label>Email</Label>
        <Input
          placeholder="Enter your Email"
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Label>Password</Label>
        <Input
          placeholder="Enter your password"
          type="password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button onClick={login} type="submit">
          Login
        </Button>
        <StatusText>{status.message}</StatusText>
      </Form>
    </Wrapper>
  );
}
const Wrapper = tw.section`h-screen flex justify-center items-center text-white`;
const Form = tw.form`rounded border w-96 h-3/6 flex flex-col justify-center p-11 `;
const Input = tw.input`mb-5 py-3 bg-transparent shadow appearance-none  border border-t-0 border-l-0 border-r-0 rounded w-full px-4 col-span-3  hover:bg-white hover:text-black focus:bg-transparent focus:text-white transition ease-in leading-tight focus:outline-none focus:shadow-outline`;
const Title = tw.h2`font-bold text-2xl mb-4`;
const Label = tw.label`mb-3`;
const Button = tw.button`bg-blue-500 rounded-lg py-2 px-2 text-xs hover:shadow-lg transistion ease-in py-3 hover:bg-blue-600`;
const StatusText = tw.p`text-red-400 mt-3`;
