import React from "react";
import Icon from "../../constants/Wonmindicons";

export default function NewsNav(props) {
  return (
    <div className="flex flex-row space-x-4 justify-between items-center">
      <span className="text-5xl font-bold">{props.pagename}</span>
      <div className="flex flex-row items-center space-x-4">
        <form>
          <label
            for="default-search"
            class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
          >
            Search
          </label>
          <div class="relative w-96">
            <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none text-gray-300">
              {Icon[0].icon}
            </div>
            <input
              type="search"
              id="default-search"
              class="block p-4 pl-10 w-full text-sm rounded-lg bg-[#1B1C20] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              placeholder="Search"
            ></input>
            <button
              type="submit"
              class="text-white absolute right-2.5 bottom-2.5 bg-[#3992FF] hover:bg-[#203756] focus:ring-4 focus:outline-none transition-all duration-100 focus:ring-blue-300 font-bold rounded-lg text-sm px-4 py-2"
            >
              Search
            </button>
          </div>
        </form>
        <img
          src={props.profilepic}
          className="mr-12 h-12 rounded-full"
          alt="IEEE White Logo"
        />
        <svg
          className="flex-shrink-0 w-5 h-5 transition duration-75 text-gray-500 group-hover:text-[#3992FF]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M5.25 9a6.75 6.75 0 0113.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 01-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 11-7.48 0 24.585 24.585 0 01-4.831-1.244.75.75 0 01-.298-1.205A8.217 8.217 0 005.25 9.75V9zm4.502 8.9a2.25 2.25 0 104.496 0 25.057 25.057 0 01-4.496 0z"
            clipRule="evenodd"
          />
        </svg>
      </div>
    </div>
  );
}
