import React from "react";
import style from "./landingPage.module.css";
import CustomLottie from "../../lotties/CustomLottie";
import whoWeAreAnimation from "../../lotties/whoWeAre.json";

export default function WhoWeAreSection() {
  return (
    <section
      className={
        " relative flex flex-col md:justify-center md:py-24 lg:flex-row lg:justify-between items-center justify-start sm:flex-col p-11    " +
        style.WhoWeAreSection
      }
    >
      <div className="relative">
        <div>
          <h1 className={"text-4xl md:text-8xl font-bold opacity-10 "}>
            Who We Are
          </h1>
          <h1
            className={
              " text-stone-800 absolute top-3 xl:top-6	text-4xl sm:text-7xl md:text-7xl md:top-8 font-bold    "
            }
          >
            Who We Are
          </h1>
        </div>
        <div>
          <p className="text-stone-800 text-base mt-11 md:text-xl md:mt-24	font-thin lg:leading-8  ">
            We are a group of volunteers mainly from the colleges of Engineering
            and Computer Science, working together through multiple different
            committees to fulfill any project and event needs, with the complete
            willingness to benefit students and advance technology for a better
            humanity.
          </p>
        </div>
      </div>
      <div className="w-96 lg:w-full">
        <CustomLottie animation={whoWeAreAnimation} />
      </div>
    </section>
  );
}
