import React, { Fragment } from "react";
import style from "../pages/Landing Page Components/landingPage.module.css";
export default function PartnersCard({ image, name }) {
  return (
    <Fragment>
      <div className={`${style.partnersCard} p-5 rounded-md   `}>
        <div className={style.cardHeader}>
          <img src={image} alt="text" className={style.partnersImage} />
        </div>
        <div>
          <p
            className={
              name.length > 20
                ? `my-12 text-sm text-center`
                : "my-12 text-xl text-center"
            }
          >
            {name}
          </p>
        </div>
      </div>
    </Fragment>
  );
}
