import React from "react";
import Bottom from "./Bottom";
import Top from "./Top";
import { useState } from "react";

const ProfileList = () => {
  const [openSide, setOpenSide] = useState(false);
  const styles = {
    show: {
      width: "100%",
    },
  };

  return (
    <div className="w-[100vh] h-screen lg:flex ">
      {/* <div className="hidden lg:block lg:w-[20%]"></div> */}
      <div
        className=" lg:flex lg:flex-col lg:w-[100%] "
        style={openSide ? styles.show : console.log("no")}
      >
        <Top></Top>
        <Bottom></Bottom>
      </div>
    </div>
  );
};

export default ProfileList;
