import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";

function FormStep5() {
    let { stepFiveData, setStepFiveData } = useContext(FormDataContext);
    let formElements = [
        {
            id: 1,
            type: "text",
            fieldName: "How many hours could you commit to IEEE per week?",
            stateName: "commitmentHours",
            blurAfterFocusStateName: "commitmentHoursBlurAfterFocus",
            errorMessage: "This field is required",
            pattern: /[0-9]/i,
        },
        {
            id: 2,
            type: "textArea",
            fieldName:
                "Would you rather be your own boss? How do you feel to taking orders or tasks?",
            stateName: "ratherBeYourOwnBoss",
            blurAfterFocusStateName: "ratherBeYourOwnBossBlurAfterFocus",
            errorMessage: "This field is required",
            pattern: /[a-z]/i,
        },
        {
            id: 3,
            type: "textArea",
            fieldName: "What sets you apart from other candidates?",
            stateName: "setsYouApartFromCandidates",
            blurAfterFocusStateName: "setsYouApartFromCandidatesBlurAfterFocus",
            errorMessage: "This field is required",
            pattern: /[a-z]/i,
        },
    ];
    let blurAfterFocus = (element) => {
        let regex = element.pattern;
        let testResult = regex.test(stepFiveData[`${element.stateName}`]);
        if (!testResult) {
            setStepFiveData({
                ...stepFiveData,
                [`${element.blurAfterFocusStateName}`]: true,
            });
        } else {
            setStepFiveData({
                ...stepFiveData,
                [`${element.blurAfterFocusStateName}`]: false,
            });
        }
    };

    return (
        <form>
            <h1 className={style.stepTitle}>Situations</h1>
            {formElements.map((element) => {
                return (
                    <div key={element.id}>
                        {element.type === "text" ? (
                            // ------------------ in case of text filed
                            <div className={style.fieldContainer}>
                                <label
                                    className={style.fieldLabel}
                                    htmlFor={element.fieldName}
                                >
                                    {element.fieldName}
                                </label>
                                <input
                                    className={style.inputField}
                                    type="text"
                                    id={element.fieldName}
                                    value={stepFiveData[`${element.stateName}`]}
                                    onChange={(e) => {
                                        setStepFiveData({
                                            ...stepFiveData,
                                            [`${element.stateName}`]:
                                                e.target.value,
                                        });
                                    }}
                                    pattern={element.pattern}
                                    onBlur={() => blurAfterFocus(element)}
                                />
                                <span
                                    className={
                                        stepFiveData[
                                            `${element.blurAfterFocusStateName}`
                                        ] === true
                                            ? style.validationErrorMessage
                                            : "hidden"
                                    }
                                >
                                    {element.errorMessage}
                                </span>
                            </div>
                        ) : element.type === "textArea" ? (
                            // ------------------ in case of textArea
                            <div className={style.fieldContainer}>
                                <label
                                    className={style.fieldLabel}
                                    htmlFor={element.fieldName}
                                >
                                    {element.fieldName}
                                </label>
                                <textarea
                                    className={style.inputField}
                                    id={element.fieldName}
                                    value={stepFiveData[`${element.stateName}`]}
                                    onChange={(e) => {
                                        setStepFiveData({
                                            ...stepFiveData,
                                            [`${element.stateName}`]:
                                                e.target.value,
                                        });
                                    }}
                                    onBlur={() => blurAfterFocus(element)}
                                />
                                <span
                                    className={
                                        stepFiveData[
                                            `${element.blurAfterFocusStateName}`
                                        ] === true
                                            ? style.validationErrorMessage
                                            : "hidden"
                                    }
                                >
                                    {element.errorMessage}
                                </span>
                            </div>
                        ) : null}
                    </div>
                );
            })}
            <div>
                <StepsButtons />
            </div>
        </form>
    );
}

export default FormStep5;
