import "./App.css";
import React from "react";
import { Routes, Route } from "react-router-dom";
import {
  ArticleDetails,
  NewsFeed,
  Membership,
  News,
  Home,
  About,
  Articles,
  Forms,
  EmailVerification,
  VerifyYourEmailPage,
  PersonalDetailsSet,
  ProfileSettings,
  ChangePassword,
  AdminLogin,
  LandingPage,
  UserProfile,
} from "./pages";
import NewsDetails from "./components/news/NewsDetails";
import { FormDataProvider } from "./context/FormContext";
import AppBar from "./components/AppBar";
import FormConfirmSuccess from "./components/form/FormConfirmSuccess";
import { AdminGuard, UserGuard } from "./Guards";
import { Redirects } from "./Hooks";

// dont change anything in this line
function App(props) {
  return (
    // <FormDataProvider>
    <>
      <AppBar />

      <Routes>
        {/* <Route path="/home" element={<Home />} /> */}
        {/* <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignIn />} /> */}
        {/* <Route path="/auth" element={<SignIn />} /> */}
        <Route path="/events" element={<News db={props.db} />} />
        <Route path="/about" element={<About />} />
        <Route path="/recruitment" element={<Membership />} />
        <Route path="/articles" element={<Articles db={props.db} />} />

        <Route
          path="/articles/:slug"
          element={<ArticleDetails db={props.db} />}
        />
        <Route path="/profile" element={<UserProfile></UserProfile>} />
        <Route
          path="/profileSet"
          element={<ProfileSettings></ProfileSettings>}
        ></Route>
        <Route
          path="/personaldata"
          element={<PersonalDetailsSet></PersonalDetailsSet>}
        ></Route>
        <Route
          path="/changepassword"
          element={<ChangePassword></ChangePassword>}
        ></Route>

        <Route path="/" element={<LandingPage db={props.db} />} />
        {/* <Route path="/recruitment_entries" element={<Forms />} /> */}
        <Route path="/events/:id" element={<NewsDetails db={props.db} />} />
        <Route path="/success" element={<FormConfirmSuccess />} />
        <Route
          path="/feed"
          element={
            <UserGuard>
              <NewsFeed />
            </UserGuard>
          }
        ></Route>
        <Route
          path="/recruitment_entries"
          element={
            <AdminGuard>
              <Forms />
            </AdminGuard>
          }
        ></Route>
        <Route
          path="/verifyyouremail/:email"
          element={<VerifyYourEmailPage />}
        />
        <Route path="/auth/admin/login" element={<AdminLogin />} />
      </Routes>
    </>
    // </FormDataProvider>
  );
}

export default App;
