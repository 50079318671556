import React, { useState, useEffect } from "react";
import axios from "axios";
import style from "./articles.module.css";
import { useNavigate } from "react-router";
import Article from "../../components/articles/Article";
import * as IoIcons from "react-icons/io5";
import Loading from "../../components/Loading";
import { collection, getDocs } from "firebase/firestore";

function Articles(props) {
  const [articles, setArticles] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  let navigate = useNavigate();

  const getData = async () => {
    await getDocs(collection(props.db, "articles")).then((element) => {
      setArticles(element.docs.map((doc) => doc.data()));
      setIsLoaded(true);
    }).catch(err => console.log(err));
  };

  // GET for all articles
  useEffect(() => {
    getData()
    // setArticles(res.data);
    // setIsLoaded(true);
    //end getting articles
  }, []);

  return isLoaded ? (
    <div className="p-5">
      <div className={" w-4/12 " + style.buttoncontroller}>
        {/* <button
          className={"text-5xl text-white rounded-full shadow-lg border hover:bg-white hover:text-black transition ease-in buttonmove " +style.buttonmove}
          onClick={() => navigate(-1)}
        >
          <IoIcons.IoArrowBackSharp />
        </button>
        */}
      </div>
      <h1
        className={
          "font-semibold xl:mb-6 text-6xl mb-4 text-white mt-24 text-center"
        }
      >
        Articles
      </h1>
      <div className="w-full">
        <div
          className={
            "grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 w-full justify-items-center"
          }
        >
          {articles.map((article) => (
            <div
              key={article.id}
              className="articleComp"
              onClick={() => {
                navigate(`/articles/${article.slug}`);
              }}
            >
              <Article
                title={article.title}
                image={article.image}
                author={article.by}
                category={article.category}
                id={article.id}
                desc={article.description.slice(0, 100) + "..."}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
}

export default Articles;
