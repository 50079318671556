import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";

function FormStep3() {
  let { stepOneData, stepThreeData, setStepThreeData } =
    useContext(FormDataContext);
  let handleTwoCheckBoxes = (e) => {
    console.log(stepThreeData.secondaryCommittee2);
    let checkeChoice = (choice) => {
      return choice !== e.target.value;
    };

    if (stepThreeData.secondaryCommittee2.length === 2) {
      if (stepThreeData.secondaryCommittee2.includes(e.target.value)) {
        setStepThreeData({
          ...stepThreeData,
          secondaryCommittee2:
            stepThreeData.secondaryCommittee2.filter(checkeChoice),
        });
      }
    } else if (stepThreeData.secondaryCommittee2.includes(e.target.value)) {
      setStepThreeData({
        ...stepThreeData,
        secondaryCommittee2:
          stepThreeData.secondaryCommittee2.filter(checkeChoice),
      });
    } else if (stepThreeData.secondaryCommittee2 == "none") {
      setStepThreeData({
        ...stepThreeData,
        secondaryCommittee2: [],
      });
    } else {
      setStepThreeData({
        ...stepThreeData,
        secondaryCommittee2: [
          ...stepThreeData.secondaryCommittee2,
          e.target.value,
        ],
      });
    }
  };

  let formElements = [
    {
      id: 1,
      type: "radio",
      fieldName: "Choose a primary committee.",
      stateName: "primaryCommittee",
      options: [
        { id: 1, value: "IT" },
        { id: 2, value: "Technical" },
        // { id: 3, value: "Simulation" },
      ],
      blurAfterFocusStateName: "primaryCommitteeBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 2,
      type: "radio",
      fieldName: "Choose a secondary committee.",
      stateName: "secondaryCommitee1",
      options: [
        { id: 1, value: "Media" },
        { id: 2, value: "Marketing" },
        { id: 3, value: "Documentation" },
        { id: 4, value: "Registration" },
        { id: 5, value: "Operations" },
      ],
      blurAfterFocusStateName: "secondaryCommitee1BlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 3,
      type: "special radio button",
      fieldName: "Choose your 2 secondary committees.",
      stateName: "secondaryCommittee2",
      options: [
        { id: 1, value: "Media" },
        { id: 2, value: "Marketing" },
        { id: 3, value: "Documentation" },
        { id: 4, value: "Registration" },
        { id: 5, value: "Operations" },
      ],
      blurAfterFocusStateName: "secondaryCommittee2BlurAfterFocus",
      visablityCondition: stepOneData.faculty,
      condtionValue: "Faculty Of Computer and Information Technology",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
  ];

  return (
    <form>
      <div>
        {formElements.map((element) => {
          return (
            <div key={element.id}>
              {element.type == "radio" ? (
                // ------------------ in case of radioButton
                <div
                  className={
                    stepOneData.faculty ==
                    "Faculty Of Computer and Information Technology"
                      ? style.fieldContainer
                      : stepOneData.faculty == "Faculty Of Engineering"
                      ? style.fieldContainer
                      : "hidden"
                  }
                >
                  <div className={style.stepSubTitle}>{element.fieldName}</div>
                  <div className={style.radioSection}>
                    {element.options.map((option) => {
                      return (
                        <label key={option.id}>
                          <input
                            className={style.radioItemInput}
                            type="radio"
                            id={option.value}
                            name={element.fieldName}
                            checked={
                              stepThreeData[`${element.stateName}`] ===
                              option.value
                            }
                            value={option.value}
                            onChange={(e) => {
                              setStepThreeData({
                                ...stepThreeData,
                                [`${element.stateName}`]: e.target.value,
                                [`${element.blurAfterFocusStateName}`]: false,
                              });
                            }}
                          />
                          <span className={style.radioItemLabel}>
                            {option.value}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <span
                    className={
                      stepThreeData[`${element.blurAfterFocusStateName}`] ==
                      true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : // ------------ in case of special radio button
              element.type == "special radio button" ? (
                <div
                  className={
                    stepOneData.faculty ==
                    "Faculty Of Computer and Information Technology"
                      ? "hidden"
                      : stepOneData.faculty == "Faculty Of Engineering"
                      ? "hidden"
                      : null
                  }
                >
                  <div className={style.stepSubTitle}>{element.fieldName}</div>
                  <div className={style.radioSection}>
                    {element.options.map((option) => {
                      return (
                        <label key={option.id}>
                          <input
                            required={true}
                            type="checkbox"
                            id={element.fieldLabel}
                            name={element.stateName}
                            className={style.radioItemInput}
                            value={option.value}
                            onClick={(e) => handleTwoCheckBoxes(e)}
                            checked={stepThreeData.secondaryCommittee2.includes(
                              option.value
                            )}
                            onChange={() => null}
                          />
                          <span className={style.radioItemLabel}>
                            {option.value}
                          </span>
                        </label>
                      );
                    })}
                    <span
                      className={
                        stepThreeData.secondaryCommittee2.length === 1
                          ? style.validationErrorMessage
                          : "hidden"
                      }
                    >
                      Choose 2 secondary committees
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div>
        <StepsButtons />
      </div>
    </form>
  );
}

export default FormStep3;
