import React from "react";
import { useState } from "react";

import Abouteditor from "./Abouteditor";
import Skills from "./Skills";

const Bottom = () => {
  const [about, setAbout] = useState(true);
  const [skills, setSkills] = useState(false);
  const [posts, setPosts] = useState(false);
  const styles = {
    blueLine: {
      borderBottom: "2px solid #3992FF",
    },
    removeLine: {
      border: "none",
    },
  };
  const tabHandler = (tab) => {
    //refactored tabhandler code
    switch (tab) {
      case "About":
        setAbout(true);
        setPosts(false);
        setSkills(false);
        break;
      case "Posts":
        setPosts(true);
        setSkills(false);
        setAbout(false);
        break;
      case "skills":
        setSkills(true);
        setAbout(false);
        setPosts(false);
        break;

      default:
        setAbout(true);
    }
  };
  //Old code for tabsHandler

  // const aboutHandler = () => {
  //   setAbout(true);
  //   setSkills(false);
  //   setPosts(false);
  // };
  // const skillsHandler = () => {
  //   setSkills(true);
  //   setAbout(false);
  //   setPosts(false);
  // };
  // const postHandler = () => {
  //   setPosts(true);
  //   setSkills(false);
  //   setAbout(false);
  // };

  return (
    <div>
      <div className="flex justify-between items-center pl-[1rem] pr-[1rem] xl:pl-[7rem] xl:pr-[7rem]">
        <span>
          <button
            onClick={() => {
              tabHandler("About");
            }}
            style={about ? styles.blueLine : styles.removeLine}
            className=" text-white"
          >
            About
          </button>{" "}
        </span>
        <span>
          <button
            onClick={() => {
              tabHandler("Posts");
            }}
            style={posts ? styles.blueLine : styles.removeLine}
            className=" text-white"
          >
            Posts
          </button>{" "}
        </span>
        <span>
          <button
            style={skills ? styles.blueLine : styles.removeLine}
            onClick={() => {
              tabHandler("skills");
            }}
            className="text-white"
          >
            Skills & Work
          </button>{" "}
        </span>
      </div>
      {about ? (
        <Abouteditor></Abouteditor>
      ) : skills ? (
        <Skills></Skills>
      ) : (
        console.log("error")
      )}
    </div>
  );
};

export default Bottom;
