import React from "react";
import style from "../../pages/About/about.module.css";
export default function CommiteeCard({ name, image, desc }) {
  return (
    <div
      className={
        style.commiteeCard +
        " mt-11 mr-11 py-11 text-white hover:scale-110 transition ease-in  cursor:pointer "
      }
    >
      <div className="m-auto inline-block text-white">{image}</div>
      <h1 className="text-3xl sm:text-xl">{name}</h1>
      <p className="text-base sm:text-sm">{desc}</p>
    </div>
  );
}
