import React from "react";
import Skill from "./Skill";
import "./Skillswindow.css";

const SkillsWindow = ({ open, onClose }) => {
  if (!open) return null;
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className="modalContainer"
    >
      <div className="modalRight">
        <p className="closeBtn" onClick={onClose}>
          X
        </p>
        <div className="content">
          <Skill></Skill>
          <Skill></Skill>
          <Skill></Skill>
          <Skill></Skill>
          <Skill></Skill>
          <Skill></Skill>
        </div>
      </div>
    </div>
  );
};

export default SkillsWindow;
