import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";

function FormDialog() {
  let { setStepperPopup, stepperPopup } = useContext(FormDataContext);
  let clickHandler = () => {
    setStepperPopup(false);
  };
  return (
    <div>
      {stepperPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="flex items-center justify-center bg-[#252525] flex-col gap-5 py-7 px-10 text-[black] border-2 border-[#3992ff] rounded-xl">
            <p className={style.popupText}>
              Please fill empty fields to be able to switch to the next step
            </p>
            <div>
              <button className={style.primaryButton} onClick={clickHandler}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormDialog;
