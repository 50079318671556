import React from "react";
import Icon from "../constants/Wonmindicons";

export default function WOnMind(props) {
  return (
    <div className="mt-10 bg-[#1B1C20] rounded-md p-4">
      <div>
        <div className="flex">
          <img
            src={props.profilepic}
            className="mr-12 h-12 rounded-full"
            alt="IEEE White Logo"
          />
          <form className="w-full">
            <label
              for="default-search"
              class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300"
            >
              Search
            </label>
            <div class="relative">
              <input
                class="block p-4 w-full text-sm rounded-lg bg-[#121316] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                placeholder="What's on your mind?"
              ></input>
            </div>
          </form>
        </div>
      </div>
      <div className="flex mt-10 ml-24 justify-between items-center ">
        <button>
          <div className="flex space-x-2">
            <div className="text-[#3992FF]">{Icon[1].icon}</div>
            <span className="transition duration-75 hover:text-[#3992FF] dark:text-white group">
              {Icon[1].title}
            </span>
          </div>
        </button>
        <button>
          <div className="flex space-x-2">
            <div className="text-[#3992FF]">{Icon[2].icon}</div>
            <span className="transition duration-75 hover:text-[#3992FF] dark:text-white group">
              {Icon[2].title}
            </span>
          </div>
        </button>
        <button className="bg-[#3992FF] w-36 rounded-md p-1 font-bold transition-all duration-100 hover:bg-[#203756]">
          Post
        </button>
      </div>
    </div>
  );
}
