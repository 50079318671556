import React, { useState } from "react";
import tw from "tailwind-styled-components";
import testimg from "../../Images/gradient-2.jpg";
import Sidebar from "../../components/Sidebar";
import Feed from "../../components/Feed";
import SidebarClosed from "../../components/SidebarClosed";
import ProfilePic from "../../Images/omar.jpg";

export default function NewsFeed() {

  const [close, setClose] = useState(false);

  function Closebtn() {
    setClose(!close);
  }
  return (
    <div className="text-white flex justify-center items-baseline w-screen h-screen">
      <Sidebar fn={Closebtn} close={close} profilepic={ProfilePic} />
      <div className="pt-[80px] px-6 h-screen w-screen">
        <div className="flex space-y-4 w-full">
          <SidebarClosed
            fn={Closebtn} close={close} profilepic={ProfilePic}
          />
          <Feed fn={Closebtn} close={close} profilepic={ProfilePic} username="Ibrahem"/>
          
        </div>
      </div>
    </div>
  );
}

// const Post = tw.div`flex flex-col h-screen bg-zinc-900 p-56 border-4`;
// const PostHeader = tw.div`flex justify-start text-white font-bold`;
// const UserProfilePhoto = tw.img`rounded-full w-full`;
// const Username = tw.p``;
