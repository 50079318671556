import React from "react";
import { Navigate } from "react-router-dom";
import GetCookie from "../Hooks/GetCookie";
import { useJwt } from "react-jwt";
const AdminGuard = ({ children }) => {
  const token = GetCookie("token");
  const decodedToken = useJwt(token);
  console.log(decodedToken);
  if (!token) return <Navigate to={"/"} />;
  else return children;
};

export default AdminGuard;
