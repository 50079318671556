import React from "react";
import { Link } from "react-router-dom";
import { BeakerIcon } from "@heroicons/react/24/solid";
import { ArrowSmallLeftIcon } from "@heroicons/react/24/solid";
import { UserIcon } from "@heroicons/react/24/solid";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import { KeyIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/solid";

const SettingsList = () => {
  return (
    <div className="flex flex-col w-screen cursor-pointer">
      <div className="flex justify-evenly	items-baseline">
        <UserIcon className="lg:w-8 w-4 md:w-8 text-[#6A6A6A]   "></UserIcon>

        <div className="flex flex-col items-start w-[17rem]">
          <p className="text-[18px] font-normal xl:text-[18px]">
            Account Information
          </p>
          <p className="text-[13px] font-extralight xl:text-[15px] text-[#6A6A6A]">
            See information about your account, download an archive of your
            data, or learn about your account deactivation options.
          </p>
        </div>
        <Link to="/personaldata">
          <ArrowSmallRightIcon className="llg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </Link>
      </div>

      <div className="flex justify-evenly	items-baseline pt-3">
        <KeyIcon className="lg:w-8 w-4 md:w-8 text-[#6A6A6A]   "></KeyIcon>

        <div className="flex flex-col items-start w-[17rem]">
          <p className="text-[15px] font-normal xl:relative xl:right-2 xl:text-[18px]">
            Reset Password
          </p>
          <p className="text-[13px] xl:relative xl:right-2 font-extralight xl:text-[15px] text-[#6A6A6A]">
            Change your password at any time.
          </p>
        </div>
        <Link to="/changepassword">
          <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </Link>
      </div>
      <div className="flex justify-evenly	items-baseline pt-3">
        <GlobeAltIcon className="lg:w-8 md:w-8 w-4 text-[#6A6A6A]   "></GlobeAltIcon>
        <div className="flex flex-col items-start w-[17rem]">
          <p className="text-[15px] font-normal xl:relative xl:right-2 xl:text-[18px]">
            Change Language
          </p>
          <p className="text-[13px] xl:relative xl:right-2 font-extralight xl:text-[15px] text-[#6A6A6A]">
            Change The Website's Language
          </p>
        </div>
        <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
      </div>
    </div>
  );
};

export default SettingsList;
