import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import {
  BsFillPersonFill,
  BsStarFill,
  BsFillChatTextFill,
  BsFillPenFill,
  BsUiChecksGrid,
} from "react-icons/bs";
import { SiIeee } from "react-icons/si";

function Stepper() {
  let {
    step,
    stepSuccess,
    setStep,
    setStepperPopup,
    closingRecruitmentPopup,
    setClosingRecruitmentPopup,
  } = useContext(FormDataContext);
  let stepperHandler = (button) => {
    switch (button.id) {
      case 1:
        setStep(button.id);
        break;
      case 2:
        if (stepSuccess.stepOneSuccess === true) {
          setStep(button.id);
        } else {
          setStepperPopup(true);
        }
        break;
      case 3:
        if (stepSuccess.stepTwoSuccess == true) {
          setStep(button.id);
        } else {
          setStepperPopup(true);
        }
        break;
      case 4:
        if (stepSuccess.stepThreeSuccess == true) {
          setStep(button.id);
        } else {
          setStepperPopup(true);
        }
        break;
      case 5:
        if (stepSuccess.stepFourSuccess == true) {
          setStep(button.id);
        } else {
          setStepperPopup(true);
        }
        break;
      case 6:
        if (stepSuccess.stepFiveSuccess == true) {
          setStep(button.id);
        } else {
          setStepperPopup(true);
        }
        break;
    }
  };

  let buttonsInfo = [
    { id: 1, name: "Introduce Yourself" },
    { id: 2, name: "Knowledge about IEEE" },
    { id: 3, name: "Committee Choice" },
    { id: 4, name: "Experience in each committee" },
    { id: 5, name: "Situations" },
    { id: 6, name: "Feedback" },
  ];

  let buttonIcon = (buttonId) => {
    switch (buttonId) {
      case 1:
        return (
          <BsFillPersonFill
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      case 2:
        return (
          <SiIeee
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      case 3:
        return (
          <BsUiChecksGrid
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      case 4:
        return (
          <BsStarFill
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      case 5:
        return (
          <BsFillChatTextFill
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      case 6:
        return (
          <BsFillPenFill
            className={buttonId === step ? "w-5 h-5 text-[#3992ff]" : "w-5 h-5"}
          />
        );
      default:
        throw new Error();
    }
  };

  return (
    <div>
      <div className="flex lg:flex-col rounded-xl">
        {buttonsInfo.map((button) => {
          return (
            <button
              key={button.id}
              className={
                step === button.id
                  ? "flex justify-center border-b-4 border-[#3992ff] pr-3 lg:border-0 lg:flex-row-reverse items-center gap-3 py-3 grow shrink cursor-not-allowed"
                  : "flex justify-center border-b-4 border-[#a3a3a3] pr-3 lg:border-0 lg:flex-row-reverse items-center gap-3 py-3 grow shrink cursor-not-allowed"
              }
              // onClick={() => stepperHandler(button)}
              onClick={() => setClosingRecruitmentPopup(true)}
            >
              <div className="hidden lg:block">
                <div
                  className={
                    step == button.id
                      ? "w-12 h-12 flex items-center justify-center border-2 border-[#3992ff] rounded-full basis-12"
                      : "w-12 h-12 flex items-center justify-center border-2 border-[#a3a3a3] rounded-full basis-12"
                  }
                >
                  {buttonIcon(button.id)}
                </div>
              </div>
              <div
                className={
                  step === button.id
                    ? "basis-40 text-right grow shrink text-[#3992ff] hidden lg:block"
                    : "basis-40 text-right grow shrink hidden lg:block"
                }
              >
                {button.name}
              </div>
              <div
                className={
                  step === button.id ? "text-[#3992ff] lg:hidden" : "lg:hidden"
                }
              >
                Step {button.id}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default Stepper;
