import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import NewsDetailsStyles from "../../components/news/NewsDetailsStyles.module.css";
import Loading from "../../components/Loading";
import { collection, query, getDocs, where } from "firebase/firestore";

const ArticleDetails = (props) => {
  const [details, setDetails] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const { slug } = useParams();
  const getDetails = async () => {
    try {
      console.log(slug)
      const q = query(
        collection(props.db, "articles"),
        where("slug", "==", slug)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        setDetails(doc.data());
        console.log(doc.data());
        setIsLoaded(true);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getDetails();
  }, []);
  const Style = {
    backgroundImage: `linear-gradient(rgb(0,0,0,0.25), #1b1c20), url(${details.image}) `,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    position: "relative",
    borderRadius: 30,
  };
  return isLoaded ? (
    <div className={NewsDetailsStyles.body}>
      <div style={Style} className="h-96 "></div>
      <div className={NewsDetailsStyles.container}>
        <div className="px-5 xl:p-24">
          <div
            className={`${NewsDetailsStyles.title} xs:col-span-2 sm:col-span-2 `}
          >
            {details.title}
            <div className={NewsDetailsStyles.description}>
              <p className="font-thin text-slate-300">{details.description}</p>
            </div>
          </div>
          <div className="text-white py-11">
            <h3 className="text-xl">Author</h3>
            <p className="text-slate-300 font-thin">{details.by}</p>
            <h3 className="text-xl">Category</h3>
            <p className="text-slate-300 font-thin">{details.category}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default ArticleDetails;
