import React from "react";
import Avatar from "./Avatar";
import PersonalInfo from "./PersonalInfo";
import { NavLink } from "react-router-dom";

// this is the top container that holds the top components like the Avatar, the joining date and a setup profile button

const Top = () => {
  return (
    <div className="  ">
      <div className="flex content-between items-center xl:items-baseline  justify-between xl:justify-between pl-[1rem] pr-[1rem]  ">
        <Avatar></Avatar>
        <NavLink to="/profileSet">
          <button className="text-white text-sm xl:mr-8   p-2 rounded-full border-2 border-gray border-solid">
            Set Up Your Profile
          </button>
        </NavLink>
      </div>
      <PersonalInfo></PersonalInfo>
    </div>
  );
};

export default Top;
