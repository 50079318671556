import React, { Fragment, useState } from "react";
import { useEffect } from "react";
import Footer from "../../components/Footer";
import axios from "axios";
import HeaderSection from "./HeaderSection";
import WhatWeDoSection from "./WhatWeDoSection";
import WhoWeAreSection from "./WhoWeAreSection";
import Partners from "./Partners";
import Loading from "../../components/Loading";
import { collection, getDocs } from "firebase/firestore";

const LandingPage = (props) => {
  const [partners, setPartners] = useState([]);

  const getData = async () => {
    await getDocs(collection(props.db, "partners"))
      .then((element) => {
        setPartners(element.docs.map((doc) => doc.data()));
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <HeaderSection />
      <WhatWeDoSection />
      <WhoWeAreSection />
      <Partners partners={partners} />
      <Footer />
    </Fragment>
  );
};

export default LandingPage;
