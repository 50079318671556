import React from "react";
import PersonalDeatilsTop from "../../../components/profile/PersonalDeatilsTop";
import { ArrowSmallRightIcon } from "@heroicons/react/24/solid";
import Sidebar from "../../../components/Sidebar";

const PersonalDetailsSet = () => {
  return (
    <div className="    pt-[5rem] lg:pl-[15px] lg:pr-[15px]  pr-[1px] pl-[1px] w-screen h-screen  bg-black">
      <PersonalDeatilsTop></PersonalDeatilsTop>

      <div className="xl:pl-2 xl:pr-2 mx-auto w-1/2">
        <Sidebar />
        <div className="flex items-baseline justify-between pl-2 pr-2 	 ">
          <div className="flex flex-col">
            <h4 className="font-light text-white">UserName</h4>
            <p className="text-[12px] font-extralight text-[#6A6A6A]">
              @Zeyad2002
            </p>
          </div>
          <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </div>
        <div className="flex items-baseline justify-between pl-2 pr-2 ">
          <div className="flex flex-col">
            <h4 className="font-light  text-white">PhoneNumber</h4>
            <p className="text-[12px] font-extralight text-[#6A6A6A]">
              +201146004472
            </p>
          </div>
          <ArrowSmallRightIcon className="lg:w-5=8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </div>
        <div className="flex items-baseline justify-between pl-2 pr-2 ">
          <div className="flex flex-col">
            <h4 className="font-light  text-white">Email</h4>
            <p className="text-[12px] font-extralight text-[#6A6A6A]">
              shaarawyziad121@gmail.com
            </p>
          </div>
          <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </div>
        <div className="flex items-baseline justify-between pl-2 pr-2 ">
          <div className="flex flex-col">
            <h4 className="font-light  text-white">Verified</h4>
            <p className="text-[12px] font-extralight text-[#6A6A6A]">
              No. <span className="text-blue-500">Request Verfication</span>
            </p>
          </div>
          <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
        </div>

        <div>
          <div className="flex items-baseline justify-between pl-2 pr-2 pt-2 ">
            <div className="flex flex-col">
              <h4 className="font-light  text-white">Birth date</h4>
              <p className="text-[12px] font-extralight text-[#6A6A6A]">
                May 28 , 2002
              </p>
            </div>
            <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
          </div>

          <div className="flex items-baseline justify-between pl-2 pr-2 pt-2 ">
            <div className="flex flex-col">
              <h4 className="font-light  text-white">Gender</h4>
            </div>
            <ArrowSmallRightIcon className="lg:w-8 md:w-8 w-8 text-[#6A6A6A]"></ArrowSmallRightIcon>
          </div>
        </div>

        <div className="relative ">
          <div className="flex  items-baseline justify-between pl-2 pr-2 pt-2 ">
            <div className="flex flex-col">
              <h4 className="font-light  text-white">Account Created</h4>
              <p className="text-[12px] font-extralight text-[#6A6A6A]">
                Nov 20, 2021, 2:20:44 AM
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalDetailsSet;
