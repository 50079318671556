import React from 'react'
import CustomLottie from '../lotties/CustomLottie'
import loadingAnimation from '../lotties/loading.json'

export default function Loading() {
  return (
    <section className='h-screen  bg-black flex align-middle justify-center text-white'>
        <div className='inline my-auto'>
        <CustomLottie animation={loadingAnimation} width = {400} height = {400}/>
        </div>
    </section >
  )
}
