import React from "react";
import { Navigate } from "react-router-dom";
import GetCookie from "../Hooks/GetCookie";
const UserGuard = ({ children, ...rest }) => {
  const token = GetCookie("token");
  if (!token) return <Navigate to={"/signin"} />;
  else return children;
};

export default UserGuard;
