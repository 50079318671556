import * as React from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { CSVLink } from "react-csv";
// import headers from "../../constants/CSVHeaders";
// import headCells from "../../constants/headCells";
import { HeaderCells, CSVHeaders } from "../../constants";
import tw from "tailwind-styled-components";
import ApplicationDetails from "./ApplicationDetails";
import * as AiIcons from "react-icons/ai";
import { DecisionButton } from "../DecisionButton";
import RemoveCookie from "../../Hooks/RemoveCookie";
import { useNavigate } from "react-router-dom";
const Boxaya = styled(Box)(({ theme }) => ({
  "& .MuiTableCell-root": {
    color: "#FFFFFF",
  },
  "& .MuiButtonBase-root": {
    color: "#FFFFFF",
    transition: "all 0.3s",
  },
  "& .MuiButtonBase-root:hover": {
    color: "#3b82f6",
  },
  "& .Mui-disabled": {
    color: "#000",
  },
  "& .MuiTablePagination-selectIcon": {
    color: "#FFFFFF",
  },
  "& .MuiToolbar-root": {
    backgroundColor: "#141414",
    color: "#FFFFFF",
  },
  "& .MuiTableHead-root": {
    backgroundColor: "#141414",
  },
  "& .MuiTableBody-root": {
    backgroundColor: "#141414",
  },
  "& .MuiTable-root": {
    backgroundColor: "#141414",
  },
  "& .Mui-active": {
    color: "#3b82f6 !important",
  },
  "& .MuiTableSortLabel-iconDirectionDesc": {
    color: "#3b82f6 !important",
  },
  "& .MuiTableSortLabel-iconDirectionAsc": {
    color: "#3b82f6 !important",
  },
}));
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {HeaderCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            colSpan={headCell.colspan ? headCell.colspan : 1}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Applicants
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function FormEntries() {
  const applicationDetailsRef = React.useRef(null);
  const [application, setApplication] = useState({});
  const [originalApplicants, setOriginalApplicants] = useState([]);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [applicants, setApplicants] = useState([]);
  const [searchBy, setSearchBy] = useState("Full Name");
  const [deletionPopup, setDeletionPopup] = useState({ view: false, id: "" });
  const navigate = useNavigate();
  const csvReport = {
    filename: "applicants.csv",
    CSVHeaders,
    data: applicants,
  };
  useEffect(() => {
    getApplicants();
  }, []);
  const getApplicants = async () => {
    try {
      const { data } = await axios.get(
        "https://ieeeaast-backend-ieeeaast.vercel.app/api/volunteers/getAllVolunteers"
      );
      setOriginalApplicants(data);
      setApplicants(data);
    } catch (err) {
      console.log(err);
    }
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://ieeeaast-backend-ieeeaast.vercel.app/api/volunteers/deleteVolunteer/${deletionPopup.id}`
      );
      setDeletionPopup({ view: false, id: "" });
      getApplicants();
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = applicants.map((n) => n.fullName);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };
  const filterBy = (status) => {
    if (status === "default") return getApplicants();
    let filtered = originalApplicants.filter((element) => {
      return status === "approved" ? element.isApproved : !element.isApproved;
    });
    setApplicants(filtered);
  };
  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const searchApplicants = (query) => {
    if (query.length === 0) {
      setApplicants(originalApplicants);
    }
    let searchedApplicants = originalApplicants.filter((element) => {
      if (searchBy === "Full Name")
        return element.fullName.toLowerCase().includes(query.toLowerCase());
      if (searchBy === "Email")
        return element.email.toLowerCase().includes(query.toLowerCase());
    });
    setApplicants(searchedApplicants);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const filterPrimaryCommittees = (committee) => {
    if (committee === "default") return getApplicants();
    let filtered = originalApplicants.filter((element) => {
      return committee.toLowerCase() === element.primaryCommittee.toLowerCase();
    });
    setApplicants(filtered);
  };
  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };
  const handleSignOut = () => {
    console.log("hello");
    RemoveCookie("token");
    navigate("/");
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - applicants.length) : 0;

  return (
    <React.Fragment>
      <section className="w-full flex flex-col justify-center items-center">
        <div
          className={`${
            deletionPopup.view ? "block" : "hidden"
          } bg-zinc-900 shadow-lg m-auto h-2/6 w-5/12 p-4 text-sm rounded  flex flex-col justify-center items-center absolute top-40  text-white border z-30`}
        >
          <p>
            Are you sure you want to delete this volunteer? This action is
            irreversible.
          </p>
          <div className="mt-5 flex justify-around  w-full">
            <button
              className="bg-zinc-800 hover:bg-zinc-700 px-4 py-2 rounded"
              onClick={() => setDeletionPopup({ view: false })}
            >
              Cancel
            </button>
            <button
              onClick={handleDelete}
              className="bg-red-500 hover:bg-red-400 px-4 py-2 rounded"
            >
              Delete
            </button>
          </div>
        </div>
        <div
          className={`${
            deletionPopup.view ? "blur-sm" : "blur-0"
          } w-full flex flex-col justify-center items-center`}
        >
          <Boxaya sx={{ width: "100%", mt: 10, px: 5, color: "white" }}>
            <div className="flex justify-between items-center">
              <Title>Applicants</Title>
              <button
                onClick={handleSignOut}
                className="rounded-lg py-2 px-2 text-xs hover:shadow-lg transistion ease-in bg-zinc-700 hover:text-shadow shadow-lg hover:bg-zinc-600"
              >
                sign out
              </button>
            </div>

            <Operations>
              <Label>Search By</Label>
              <Selection
                placeholder="search by"
                onChange={(e) => setSearchBy(e.target.value)}
              >
                <Option value="Full Name">Full Name</Option>
                <Option value="Email">Email</Option>
              </Selection>
              <SearchInput
                type={"search"}
                placeholder={`search by ${searchBy}`}
                onChange={(e) => searchApplicants(e.target.value)}
              />
              <Label>Filter By Status</Label>
              <Selection onChange={(e) => filterBy(e.target.value)}>
                <Option value="default">Filter by</Option>
                <Option value="approved">ACCEPTED</Option>
                <Option value="rejected">REJECTED</Option>
              </Selection>
              <Label>Filter by Primary Committees</Label>
              <Selection
                onChange={(e) => filterPrimaryCommittees(e.target.value)}
              >
                <Option value="default">Filter by Primary Committees</Option>
                <Option value="it">IT</Option>
                <Option value="technical">Technical</Option>
              </Selection>
            </Operations>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={applicants.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                   rows.slice().sort(getComparator(order, orderBy)) */}
                  {stableSort(applicants, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      if (
                        row.secondaryCommitee2 &&
                        row.secondaryCommitee2.length === 2
                      ) {
                        [row.secondaryCommitee1, row.secondaryCommitee2] =
                          row.secondaryCommitee2;
                      }
                      const isItemSelected = isSelected(row.fullName);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.id}
                          selected={isItemSelected}
                        >
                          <TableCell>
                            <button
                              onClick={() => {
                                setApplication(row);
                                window.scrollTo({
                                  top:
                                    applicationDetailsRef.current.getBoundingClientRect()
                                      .top - 100,
                                  behavior: "smooth",
                                });
                              }}
                              className="bg-blue-500 px-5 py-2 rounded-lg text-xs hover:bg-blue-800 transition ease-in"
                            >
                              Details
                            </button>
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row.fullName}
                          </TableCell>
                          <TableCell align="right">{row.email}</TableCell>
                          <TableCell align="right">{row.phoneNumber}</TableCell>
                          <TableCell align="right">
                            {row.primaryCommittee}
                          </TableCell>
                          <TableCell align="right">
                            {row.secondaryCommitee1}
                          </TableCell>
                          <TableCell align="right">
                            {row.secondaryCommitee2}
                          </TableCell>
                          <TableCell className="border flex items-center justify-between">
                            <DeleteButton
                              onClick={() =>
                                setDeletionPopup({ view: true, id: row.id })
                              }
                            >
                              <AiIcons.AiFillDelete />
                            </DeleteButton>
                          </TableCell>
                          <TableCell>
                            <DecisionButton
                              applicant={row}
                              isApproved={row.isApproved}
                              getApplicants={getApplicants}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30, 40, 50]}
              component="div"
              count={applicants.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <FormControlLabel
              control={<Switch checked={dense} onChange={handleChangeDense} />}
              label="Dense padding"
            />
            <BoxContainer>
              <CSVLink
                className="text-white bg-blue-600 py-3 px-4 ml-auto rounded my-11  "
                {...csvReport}
              >
                Export to CSV
              </CSVLink>
            </BoxContainer>
          </Boxaya>
          <div ref={applicationDetailsRef}>
            <ApplicationDetails application={application} />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
const BoxContainer = tw.div`w-full flex flex-row-reverse`;
const SearchInput = tw.input`border border-gray-300 bg-transparent rounded-lg px-5 py-1 ml-auto`;
const Operations = tw.div`w-full flex items-center mb-3 shadow-lg `;
const Selection = tw.select`border border-gray-300 bg-transparent rounded-lg px-5 py-1 text-xs`;
const Option = tw.option` bg-transparent text-white bg-zinc-900 text-xs`;
const Label = tw.h2` text-base mx-4`;
const Title = tw.h2`text-3xl font-bold mb-8 `;
const DeleteButton = tw.button`p-3 bg-zinc-800 rounded-lg text-white hover:bg-zinc-500 text-xs`;
