import React from "react";
import Logo from "../Images/White Overlay (2).png";
import Icon from "../constants/Icons";
import { Link } from "react-router-dom";

export default function SidebarOpen(props) {
  return (
    <div className="py-4 px-3 ">
      <div>
        <div className="flex flex-row pl-2.5 mt-16">
          <div className="flex">
            <img src={Logo} className="mr-4 h-12" alt="IEEE White Logo" />
            <div>
              <span className="text-md font-bold text-white">
                IEEE AAST Alex SB
              </span>
              <p className="text-sm font-extralight">Est. 2002</p>
            </div>
          </div>
          <div className="ml-auto">
            <button onClick={props.fn}>
              <svg
                className="w-12 h-12 p-2 bg-[#203756] rounded-[20px]"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm5.03 4.72a.75.75 0 010 1.06l-1.72 1.72h10.94a.75.75 0 010 1.5H10.81l1.72 1.72a.75.75 0 11-1.06 1.06l-3-3a.75.75 0 010-1.06l3-3a.75.75 0 011.06 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-3 mb-4 bg-opacity-25 bg-[#3992FF] h-32 rounded-[20px]">
          <div className="flex">
            <div>
              <img
                src={props.profilepic}
                className="mr-16 h-16 rounded-full"
                alt="IEEE White Logo"
              />
            </div>
            <div>
              <span className="text-sm font-bold text-white">
                Ibrahem Lotfy
              </span>
              <p className="text-xs font-extralight">
                Current Volunteer Since September 2020
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-around">
            <div className="">
              <div className="flex flex-row justify-center">
                <span className="text-[11px] text-white ">3.2k</span>
              </div>
              <p className="text-xs font-extralight">Followers</p>
            </div>
            <div className="">
              <div className=" flex flex-row justify-center">
                <span className="text-[11px] text-white ">468</span>
              </div>
              <p className="text-xs font-extralight">Following</p>
            </div>
            <div className="">
              <div className=" flex flex-row justify-center">
                <span className="text-[11px] text-white ">43</span>
              </div>
              <p className="text-xs font-extralight">Posts</p>
            </div>
          </div>
        </div>
      </div>
      <ul className="space-y-4">
        <li>
          <Link
            to={Icon[0].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 dark:text-[#3992FF] group"
          >
            {Icon[0].icon}
            <span className="ml-3 text-sm text-[#3992FF]  transition duration-75">
              {Icon[0].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[1].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white group"
          >
            {Icon[1].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[1].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[2].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[2].icon}

            <span className="ml-3 text-sm transition duration-75">
              {Icon[2].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[3].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[3].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[3].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[4].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[4].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[4].title}
            </span>
          </Link>
        </li>
      </ul>
      <ul className="pt-4 mt-4 space-y-4 border-t border-gray-200 dark:border-gray-700">
        <li>
          <Link
            to={Icon[5].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[5].icon}
            <span className="ml-4 transition duration-75">{Icon[5].title}</span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[6].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[6].icon}

            <span className="ml-3 text-sm transition duration-75">
              {Icon[6].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[7].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[7].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[7].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[8].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[8].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[8].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[9].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[9].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[9].title}
            </span>
          </Link>
        </li>
        <li>
          <Link
            to={Icon[10].path}
            className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
          >
            {Icon[10].icon}
            <span className="ml-3 text-sm transition duration-75">
              {Icon[10].title}
            </span>
          </Link>
        </li>
      </ul>
    </div>
  );
}
