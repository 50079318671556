import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

function FormSubmitPopup() {
  const navigate = useNavigate();
  const [status, setStatus] = useState("submit");
  let { submitPopup, setSubmitPopup, data, setStep } =
    useContext(FormDataContext);
  let submitHandler = async (e) => {
    setStatus("loading");
    try {
      e.preventDefault();
      await fetch(
        "https://ieeeaast-backend-ieeeaast.vercel.app/api/volunteers/addVolunteer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      )
        .then((res) => res.json())
        .then(() => setStep(1))
        .then(() => localStorage.setItem("didFillApplication", "true"))
        .then(() => navigate("/success"))
        .then(() => setStatus("submit"));
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      {submitPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
          <div className="flex items-center justify-center bg-[#252525] flex-col gap-5 py-10 px-10 text-[black] border-2 border-[#3992ff] rounded-xl">
            <p className={style.popupText}>
              Are you sure you want to submit the form?
            </p>
            <div className="flex gap-5">
              <button
                className={style.secondaryButton}
                onClick={() => setSubmitPopup(false)}
              >
                Cancel
              </button>
              <button
                disabled={status === "loading?" ? true : false}
                className={`${style.primaryButton} ${
                  status === "loading" ? "bg-blue-400" : "bg-blue-500"
                }`}
                onClick={(e) => submitHandler(e)}
              >
                {status}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FormSubmitPopup;
