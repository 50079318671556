import React, { Fragment } from "react";
import * as BiIcons from "react-icons/bi";
import * as GiIcons from "react-icons/gi";
import * as BsIcons from "react-icons/bs";
import img from "../Images/social-media-influencer.png";
export default function Footer() {
  return (
    <Fragment>
      <section
        className="flex flex-col p-4 xl:flex-row text-white items-center flex-wrap md:flex-col justfy-center xl:justify-between xl:px-11 py-7"
        id="contactUs"
      >
        <div className="flex flex-col  xl:flex-1 justify-center  ">
          <h1 className="text-white text-5xl font-bold xs:text-2xl ">
            Contact Us
          </h1>

          <div className="flex mt-7 ">
            <BiIcons.BiCurrentLocation className="text-5xl sm:text-3xl" />
            <h5 className="text-white ml-5 text-4xl w-9/12 sm:text-2xl ">
              Find us at AAST Abu Qir Campus
            </h5>
          </div>
          <p className="text-gray-400 ml-14 ">
          Al Akademia, Abu Qir Al Gharbeyah, Montaza 2, Alexandria Governorate
          </p>

          <div className="flex mt-11">
            <GiIcons.GiSmartphone className="text-white text-5xl  " />
            <h1 className="text-white ml-5 text-4xl sm:text-2xl">
              Follow Our Social Media Accounts
            </h1>
          </div>
          <span className="flex text-white text-4xl ml-16 xs:text-3xl">
            <a href="https://www.linkedin.com/company/ieeeaast/mycompany/">
              <BsIcons.BsLinkedin className="mr-3 hover:text-sky-500 hover:scale-110 transition-all ease-in-out cursor-pointer" />
            </a>
            <a href="https://www.instagram.com/ieeeaast/">
              <BsIcons.BsInstagram className="mr-3 hover:text-sky-500 hover:scale-110 transition-all ease-in-out cursor-pointer" />
            </a>
            <a href="https://www.facebook.com/IEEEAAST/">
              <BsIcons.BsFacebook className="mr-3 hover:text-sky-500 hover:scale-110 transition-all ease-in-out cursor-pointer" />
            </a>
          </span>
        </div>
        <div>
          <img src={img} alt="" className="lg:w-[35vw] w-[50vw] py-20 lg:py-0" />
        </div>
      </section>
    </Fragment>
  );
}
