import React from "react";

const Skill = () => {
  return (
    <div className="flex flex-col pt-2  border-b border-slate-500 border-solid w-full">
      <h3 className="pb-4 pl-4 text-white">React.js</h3>
      <div className="flex justify-start items-start pl-4">
        <img
        className="w-6 h-6 mr-3"
          src="https://media-exp1.licdn.com/dms/image/C4E0BAQGoRdJYxmIDhQ/company-logo_100_100/0/1519856132409?e=1674691200&v=beta&t=uBoox5zrTSKP1FRm8qkeEYp9n8myX6daCRcrB5BWh6w"
          alt="nothing to preview"
        />
        <p>Volunteer at IEEE</p>
      </div>
    </div>
  );
};

export default Skill;
