import React from "react";
import ResetPassForm from "../../../components/profile/ResetPassForm";
import ChangePTopBar from "../../../components/profile/ChangePTopBar";

const ChangePassword = () => {
  return (
    <div className="   pt-[5rem]  pr-[1px] pl-[1px] h-[100vh]   bg-black">
      <ChangePTopBar></ChangePTopBar>
      <div className="xl:flex xl:justify-center">
        {/* <div className="hidden xl:block xl:w-[20%] ">hello</div> */}
        <div className="">
          <ResetPassForm></ResetPassForm>
        </div>
      </div>
    </div>
    //  xl:w-[80%] xl:pt-7
    //
  );
};

export default ChangePassword;
