import { createContext, useState } from "react";

const FormDataContext = createContext({});

export const FormDataProvider = ({ children }) => {
    let [step, setStep] = useState(1);
    // --------------------------------------- steps states
    let [stepOneData, setStepOneData] = useState({
        fullName: "",
        address: "",
        phoneNumber: "",
        email: "",
        gpa: "",
        semester: "",
        faculty: "",
        department: "",
        civilWork: "",
        skills: "",
        hobbies: "",
        describeYourself: "",
        achievemenets: "",
        // organizationNames: "",
        // organizationNamesAfterFocus: false,

        fullNameBlurAfterFocus: false,
        addressBlurAfterFocus: false,
        phoneNumberBlurAfterFocus: false,
        emailBlurAfterFocus: false,
        gpaBlurAfterFocus: false,
        semesterBlurAfterFocus: false,
        facultyBlurAfterFocus: false,
        departmentBlurAfterFocus: false,
        civilWorkBlurAfterFocus: false,
        skillsBlurAfterFocus: false,
        hobbiesBlurAfterFocus: false,
        describeYourselfBlurAfterFocus: false,
        achievemenetsBlurAfterFocus: false,
    });
    let [stepTwoData, setStepTwoData] = useState({
        ieeeLettersAbv: "",
        whatDoYouKnowAboutIeee: "",
        whyDoYouWantToJoinIEEE: "",
        didAttendEvents: "",
        eventNames: "",

        ieeeLettersAbvBlurAfterFocus: false,
        whatDoYouKnowAboutIeeeBlurAfterFocus: false,
        whyDoYouWantToJoinIEEEBlurAfterFocus: false,
        didAttendEventsBlurAfterFocus: false,
        eventNamesBlurAfterFocus: false,
    });
    let [stepThreeData, setStepThreeData] = useState({
        primaryCommittee: "",
        secondaryCommitee1: "",
        secondaryCommittee2: [],

        primaryCommitteeBlurAfterFocus: false,
        secondaryCommitee1BlurAfterFocus: false,
        secondaryCommittee2BlurAfterFocus: false,
    });
    let [stepFourData, setStepFourData] = useState({
        ITExperience: "",
        technicalExperience: "",
        mediaExperience: "",
        marketingExperience: "",
        documentationExperience: "",
        registrationExperience: "",
        operationExperience: "",
        //
        ITExperienceBlurAfterFocus: false,
        technicalExperienceBlurAfterFocus: false,
        mediaExperienceBlurAfterFocus: false,
        marketingExperienceBlurAfterFocus: false,
        documentationExperienceBlurAfterFocus: false,
        registrationExperienceBlurAfterFocus: false,
        operationExperienceBlurAfterFocus: false,
    });
    let [stepFiveData, setStepFiveData] = useState({
        commitmentHours: "",
        ratherBeYourOwnBoss: "",
        setsYouApartFromCandidates: "",
        //
        commitmentHoursBlurAfterFocus: false,
        ratherBeYourOwnBossBlurAfterFocus: false,
        setsYouApartFromCandidatesBlurAfterFocus: false,
    });
    let [stepSixData, setStepSixData] = useState({
        howDidYouKnowAboutRecruitment: "",
        volunteersName: "",
        friendsName: "",
        otherName: "",
        //
        howDidYouKnowAboutRecruitmentBlurAfterFocus: false,
        volunteersNameBlurAfterFocus: false,
        friendsNameBlurAfterFocus: false,
        otherNameBlurAfterFocus: false,
    });
    let [stepSuccess, setstepSuccess] = useState({
        stepOneSuccess: false,
        stepTwoSuccess: false,
        stepThreeSuccess: false,
        stepFourSuccess: false,
        stepFiveSuccess: false,
        stepSixSuccess: false,
    });
    // --------------------------------------- submit data state
    let data = {
        fullName: stepOneData.fullName,
        address: stepOneData.address,
        phoneNumber: stepOneData.phoneNumber,
        email: stepOneData.email,
        gpa: stepOneData.gpa,
        semester: stepOneData.semester,
        faculty: stepOneData.faculty,
        department: stepOneData.department,
        civilWork: stepOneData.civilWork,
        skills: stepOneData.skills,
        hobbies: stepOneData.hobbies,
        describeYourself: stepOneData.describeYourself,
        achievemenets: stepOneData.achievemenets,
        organizationNames: stepOneData.organizationNames,

        ieeeLettersAbv: stepTwoData.ieeeLettersAbv,
        whatDoYouKnowAboutIeee: stepTwoData.whatDoYouKnowAboutIeee,
        whyDoYouWantToJoinIEEE: stepTwoData.whyDoYouWantToJoinIEEE,
        didAttendEvents: stepTwoData.didAttendEvents,
        eventNames: stepTwoData.eventNames,

        primaryCommittee: stepThreeData.primaryCommittee,
        secondaryCommitee1: stepThreeData.secondaryCommitee1,
        secondaryCommitee2: stepThreeData.secondaryCommittee2,
        ITExperience: stepFourData.ITExperience,
        technicalExperience: stepFourData.technicalExperience,
        mediaExperience: stepFourData.mediaExperience,
        marketingExperience: stepFourData.marketingExperience,
        documentationExperience: stepFourData.documentationExperience,
        registrationExperience: stepFourData.registrationExperience,
        operationExperience: stepFourData.operationExperience,

        commitmentHours: stepFiveData.commitmentHours,
        ratherBeYourOwnBoss: stepFiveData.ratherBeYourOwnBoss,
        setsYouApartFromCandidates: stepFiveData.setsYouApartFromCandidates,

        howDidYouKnowAboutRecruitment:
            stepSixData.howDidYouKnowAboutRecruitment,
        volunteersName: stepSixData.volunteersName,
        friendsName: stepSixData.friendsName,
        otherName: stepSixData.otherName,
    };
    // --------------------------------------- pop up states
    let [stepperPopup, setStepperPopup] = useState(false);
    let [submitPopup, setSubmitPopup] = useState(false);
    let [closingRecruitmentPopup, setClosingRecruitmentPopup] = useState(false);

    // --------------------------------------- functions
    let checkSuccess = () => {
        switch (step) {
            case 1:
                if (
                    stepOneData.fullName === "" ||
                    stepOneData.address === "" ||
                    stepOneData.phoneNumber === "" ||
                    stepOneData.email === "" ||
                    stepOneData.gpa === "" ||
                    stepOneData.semester === "" ||
                    stepOneData.faculty === "" ||
                    stepOneData.department === "" ||
                    stepOneData.civilWork === "" ||
                    stepOneData.skills === "" ||
                    stepOneData.hobbies === "" ||
                    stepOneData.describeYourself === "" ||
                    stepOneData.achievemenets === ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepOneSuccess: false,
                    });
                } else if (
                    stepOneData.fullName !== "" &&
                    stepOneData.address !== "" &&
                    stepOneData.phoneNumber !== "" &&
                    stepOneData.email !== "" &&
                    stepOneData.gpa !== "" &&
                    stepOneData.semester !== "" &&
                    stepOneData.faculty !== "" &&
                    stepOneData.department !== "" &&
                    stepOneData.civilWork !== "" &&
                    stepOneData.skills !== "" &&
                    stepOneData.hobbies !== "" &&
                    stepOneData.describeYourself !== "" &&
                    stepOneData.achievemenets !== ""
                    // stepTwoData.organizationNames == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepOneSuccess: true,
                    });
                }
                break;
            case 2:
                if (
                    stepTwoData.ieeeLettersAbv == "" ||
                    stepTwoData.whatDoYouKnowAboutIeee == "" ||
                    stepTwoData.whyDoYouWantToJoinIEEE == "" ||
                    stepTwoData.didAttendEvents == "" ||
                    stepTwoData.eventNames == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepTwoSuccess: false,
                    });
                } else if (
                    stepTwoData.ieeeLettersAbv !== "" &&
                    stepTwoData.whatDoYouKnowAboutIeee !== "" &&
                    stepTwoData.whyDoYouWantToJoinIEEE !== "" &&
                    stepTwoData.didAttendEvents !== "" &&
                    stepTwoData.eventNames !== ""
                    // stepTwoData.organizationNames == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepTwoSuccess: true,
                    });
                }
                break;
            case 3:
                if (
                    stepThreeData.primaryCommittee == "" ||
                    stepThreeData.secondaryCommitee1 == "" ||
                    stepThreeData.secondaryCommittee2 == "" ||
                    stepThreeData.secondaryCommittee2.length != 2
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepThreeSuccess: false,
                    });
                } else if (
                    stepThreeData.primaryCommittee !== "" &&
                    stepThreeData.secondaryCommitee1 !== "" &&
                    stepThreeData.secondaryCommittee2 !== "" &&
                    stepThreeData.secondaryCommittee2.length === 2
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepThreeSuccess: true,
                    });
                }
                break;
            case 4:
                if (
                    stepFourData.ITExperience == "" ||
                    stepFourData.technicalExperience == "" ||
                    stepFourData.mediaExperience == "" ||
                    stepFourData.marketingExperience == "" ||
                    stepFourData.documentationExperience == "" ||
                    stepFourData.registrationExperience == "" ||
                    stepFourData.operationExperience == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepFourSuccess: false,
                    });
                } else if (
                    stepFourData.ITExperience !== "" &&
                    stepFourData.technicalExperience !== "" &&
                    stepFourData.mediaExperience !== "" &&
                    stepFourData.marketingExperience != "" &&
                    stepFourData.documentationExperience !== "" &&
                    stepFourData.registrationExperience !== "" &&
                    stepFourData.operationExperience !== ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepFourSuccess: true,
                    });
                }
                break;
            case 5:
                if (
                    stepFiveData.commitmentHours == "" ||
                    stepFiveData.ratherBeYourOwnBoss == "" ||
                    stepFiveData.setsYouApartFromCandidates == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepFiveSuccess: false,
                    });
                } else if (
                    stepFiveData.commitmentHours !== "" &&
                    stepFiveData.ratherBeYourOwnBoss !== "" &&
                    stepFiveData.setsYouApartFromCandidates !== ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepFiveSuccess: true,
                    });
                }
                break;
            case 6:
                if (
                    stepSixData.howDidYouKnowAboutRecruitment == "" ||
                    stepSixData.volunteersName == "" ||
                    stepSixData.friendsName == "" ||
                    stepSixData.otherName == ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepSixSuccess: false,
                    });
                } else if (
                    stepSixData.howDidYouKnowAboutRecruitment !== "" &&
                    stepSixData.volunteersName !== "" &&
                    stepSixData.friendsName !== "" &&
                    stepSixData.otherName !== ""
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepSixSuccess: true,
                    });
                } else if (
                    stepSixData.howDidYouKnowAboutRecruitment !== "" &&
                    stepSixData.volunteersName !== "" &&
                    stepSixData.friendsName == "none" &&
                    stepSixData.otherName == "none"
                ) {
                    setstepSuccess({
                        ...stepSuccess,
                        stepSixSuccess: true,
                    });
                }
        }
    };

    let ignoreStepTwoHiddenFields = () => {
        // step two
        if (stepTwoData.didAttendEvents == "No") {
            setStepTwoData({ ...stepTwoData, eventNames: "none" });
        } else if (stepTwoData.didAttendEvents == "Yes") {
            setStepTwoData({
                ...stepTwoData,
                eventNames: stepTwoData.eventNames,
            });
        } else if (stepTwoData.eventNames === "none") {
            setStepTwoData({
                ...stepTwoData,
                eventNames: "",
            });
        }
    };

    let ignoreStepThreeHiddenFields = () => {
        // step three
        if (
            stepOneData.faculty ===
                "Faculty Of Computer and Information Technology" ||
            stepOneData.faculty === "Faculty Of Engineering"
        ) {
            setStepThreeData({
                ...stepThreeData,
                primaryCommittee: stepThreeData.primaryCommittee,
                secondaryCommitee1: stepThreeData.secondaryCommitee1,
                secondaryCommittee2: "none",
            });
        } else if (
            stepOneData.faculty !==
                "Faculty Of Computer and Information Technology" &&
            stepOneData.faculty !== "Faculty Of Engineering"
        ) {
            setStepThreeData({
                ...stepThreeData,
                primaryCommittee: "none",
                secondaryCommitee1: "none",
                secondaryCommittee2: stepThreeData.secondaryCommittee2,
            });
        }
    };

    let ignoreStepSixHiddenFields = () => {
        if (
            stepSixData.howDidYouKnowAboutRecruitment == "Facebook" ||
            stepSixData.howDidYouKnowAboutRecruitment == "Wall Poster" ||
            stepSixData.howDidYouKnowAboutRecruitment == "Booth" ||
            stepSixData.howDidYouKnowAboutRecruitment == "Instgram" ||
            stepSixData.howDidYouKnowAboutRecruitment == "LinkedIn"
        ) {
            setStepSixData({
                ...stepSixData,
                volunteersName: "none",
                friendsName: "none",
                otherName: "none",
            });
        } else if (
            stepSixData.howDidYouKnowAboutRecruitment !== "Facebook" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Wall Poster" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Booth" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Instgram" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "LinkedIn"
        ) {
            setStepSixData({
                ...stepSixData,
                volunteersName: "",
                friendsName: "",
                otherName: "",
            });
        }
        if (
            stepSixData.howDidYouKnowAboutRecruitment !== "Facebook" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Wall Poster" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Booth" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Instgram" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "LinkedIn" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Friend" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Other" &&
            stepSixData.howDidYouKnowAboutRecruitment == "Volunteer"
        ) {
            setStepSixData({
                ...stepSixData,
                volunteersName: "",
                friendsName: "none",
                otherName: "none",
            });
        }
        if (
            stepSixData.howDidYouKnowAboutRecruitment !== "Facebook" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Wall Poster" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Booth" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Instgram" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "LinkedIn" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Friend" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Volunteer" &&
            stepSixData.howDidYouKnowAboutRecruitment == "Other"
        ) {
            setStepSixData({
                ...stepSixData,
                volunteersName: "none",
                friendsName: "none",
                otherName: "",
            });
        }
        if (
            stepSixData.howDidYouKnowAboutRecruitment !== "Facebook" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Wall Poster" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Booth" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Instgram" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "LinkedIn" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Volunteer" &&
            stepSixData.howDidYouKnowAboutRecruitment !== "Other" &&
            stepSixData.howDidYouKnowAboutRecruitment == "Friend"
        ) {
            setStepSixData({
                ...stepSixData,
                volunteersName: "none",
                friendsName: "",
                otherName: "none",
            });
        }
    };

    return (
        <FormDataContext.Provider
            value={{
                step,
                setStep,

                stepperPopup,
                setStepperPopup,

                submitPopup,
                setSubmitPopup,

                stepOneData,
                setStepOneData,
                stepTwoData,
                setStepTwoData,
                stepThreeData,
                setStepThreeData,
                stepFourData,
                setStepFourData,
                stepFiveData,
                setStepFiveData,
                stepSixData,
                setStepSixData,

                data,

                ignoreStepTwoHiddenFields,
                ignoreStepThreeHiddenFields,
                ignoreStepSixHiddenFields,

                stepSuccess,
                checkSuccess,

                closingRecruitmentPopup,
                setClosingRecruitmentPopup,
            }}
        >
            {children}
        </FormDataContext.Provider>
    );
};

export default FormDataContext;
