import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";

function FormStep2() {
  let { stepTwoData, setStepTwoData } = useContext(FormDataContext);

  let formElements = [
    {
      id: 1,
      type: "text",
      fieldName: "What does IEEE abbreviation stand for?",
      stateName: "ieeeLettersAbv",
      blurAfterFocusStateName: "ieeeLetterAbvBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 2,
      type: "text",
      fieldName: "What do you know about IEEE?",
      stateName: "whatDoYouKnowAboutIeee",
      blurAfterFocusStateName: "whatDoYouKnowAboutIeeeBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 3,
      type: "text",
      fieldName: "Why do you want to join IEEE?",
      stateName: "whyDoYouWantToJoinIEEE",
      blurAfterFocusStateName: "whyDoYouWantToJoinIEEEBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 4,
      type: "radio",
      fieldName: "Did you attend any IEEE events before?",
      stateName: "didAttendEvents",
      options: [
        { id: 1, value: "Yes" },
        { id: 2, value: "No" },
      ],
      blurAfterFocusStateName: "didAttendEventsBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 5,
      type: "text with visability condition",
      fieldName: "Can you please provide the events' names that you attended?",
      stateName: "eventNames",
      visablityCondition: stepTwoData.didAttendEvents,
      condtionValue: "Yes",
      blurAfterFocusStateName: "eventNamesBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
  ];

  let blurAfterFocus = (element) => {
    let regex = element.pattern;
    let testResult = regex.test(stepTwoData[`${element.stateName}`]);
    if (!testResult) {
      setStepTwoData({
        ...stepTwoData,
        [`${element.blurAfterFocusStateName}`]: true,
      });
    } else {
      setStepTwoData({
        ...stepTwoData,
        [`${element.blurAfterFocusStateName}`]: false,
      });
    }
  };

  return (
    <form>
      <h1 className={style.stepTitle}>Knowledge about IEEE</h1>
      {formElements.map((element) => {
        return (
          <div key={element.id}>
            {element.type == "text" ? (
              // ------------------ in case of text filed
              <div className={style.fieldContainer}>
                <label className={style.fieldLabel} htmlFor={element.fieldName}>
                  {element.fieldName}
                </label>
                <input
                  className={style.inputField}
                  type="text"
                  id={element.fieldName}
                  value={stepTwoData[`${element.stateName}`]}
                  onChange={(e) => {
                    setStepTwoData({
                      ...stepTwoData,
                      [`${element.stateName}`]: e.target.value,
                    });
                  }}
                  pattern={element.pattern}
                  onBlur={() => blurAfterFocus(element)}
                />
                <span
                  className={
                    stepTwoData[`${element.blurAfterFocusStateName}`] == true
                      ? style.validationErrorMessage
                      : "hidden"
                  }
                >
                  {element.errorMessage}
                </span>
              </div>
            ) : // ------------------ in case of radioButton
            element.type == "radio" ? (
              <div className={style.fieldContainer}>
                <div className={style.fieldLabel}>{element.fieldName}</div>
                <div className={style.radioSection}>
                  {element.options.map((option) => {
                    return (
                      <label key={option.id}>
                        <input
                          className={style.radioItemInput}
                          type="radio"
                          id={option.value}
                          name={element.fieldName}
                          value={option.value}
                          checked={
                            stepTwoData[`${element.stateName}`] === option.value
                          }
                          onChange={(e) => {
                            setStepTwoData({
                              ...stepTwoData,
                              [`${element.stateName}`]: e.target.value,
                              [`${element.blurAfterFocusStateName}`]: false,
                            });
                          }}
                        />
                        <span className={style.radioItemLabel}>
                          {option.value}
                        </span>
                      </label>
                    );
                  })}
                </div>
                <span
                  className={
                    stepTwoData[`${element.blurAfterFocusStateName}`] == true
                      ? style.validationErrorMessage
                      : "hidden"
                  }
                >
                  {element.errorMessage}
                </span>
              </div>
            ) : element.type == "text with visability condition" ? (
              // ------------------ in case of text filed with visability condition
              <div
                className={
                  element.visablityCondition == element.condtionValue
                    ? style.fieldContainer
                    : "hidden"
                }
              >
                <label htmlFor={element.fieldName} className={style.fieldLabel}>
                  {element.fieldName}
                </label>
                <input
                  className={style.inputField}
                  type="text"
                  id={element.fieldName}
                  value={stepTwoData[`${element.stateName}`]}
                  onChange={(e) => {
                    setStepTwoData({
                      ...stepTwoData,
                      [`${element.stateName}`]: e.target.value,
                    });
                  }}
                  pattern={element.pattern}
                  onBlur={() => blurAfterFocus(element)}
                />
                <span
                  className={
                    stepTwoData[`${element.blurAfterFocusStateName}`] == true
                      ? style.validationErrorMessage
                      : "hidden"
                  }
                >
                  {element.errorMessage}
                </span>
              </div>
            ) : null}
          </div>
        );
      })}
      <div>
        <StepsButtons />
      </div>
    </form>
  );
}

export default FormStep2;
