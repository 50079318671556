import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/White Overlay (2).png";
import Icon from "../constants/Icons";

export default function SidebarClosed(props) {
  return (
    <div>
      <button onClick={props.fn}>
        <svg
          className="hidden lg:block w-12 h-12 p-2 bg-[#203756] rounded-[20px]"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M7.5 3.75A1.5 1.5 0 006 5.25v13.5a1.5 1.5 0 001.5 1.5h6a1.5 1.5 0 001.5-1.5V15a.75.75 0 011.5 0v3.75a3 3 0 01-3 3h-6a3 3 0 01-3-3V5.25a3 3 0 013-3h6a3 3 0 013 3V9A.75.75 0 0115 9V5.25a1.5 1.5 0 00-1.5-1.5h-6zm10.72 4.72a.75.75 0 011.06 0l3 3a.75.75 0 010 1.06l-3 3a.75.75 0 11-1.06-1.06l1.72-1.72H9a.75.75 0 010-1.5h10.94l-1.72-1.72a.75.75 0 010-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      <Link to="/">
        <img src={Logo} className="h-10 my-2" alt="Logo" />
      </Link>
      <ul className="space-y-4 ml-4 mt-4">
        {Icon.map((element) => (
          <li>
            <Link
              to={element.path}
              className="flex items-center text-base font-normal text-white rounded-lg transition duration-75 focus:text-[#3992FF] group"
            >
              {element.icon}
            </Link>
          </li>
        ))}
        {/* <li>
                    <Link
                        to={Icon[1].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[1].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[2].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[2].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[3].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[3].icon}

                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[4].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[4].icon}
                    </Link>
                </li>
            </ul>
            <ul className="pt-4 mt-4 ml-4 space-y-4 border-spacing-1 border-gray-200 dark:border-gray-700">
                <li>
                    <Link
                        to={Icon[5].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[5].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[6].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[6].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[7].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[7].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[8].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[8].icon}
                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[9].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[9].icon}

                    </Link>
                </li>
                <li>
                    <Link
                        to={Icon[10].path}
                        className="flex items-center  text-base font-normal text-gray-900 rounded-lg transition duration-75 hover:text-[#3992FF] dark:text-white grou"
                    >
                        {Icon[10].icon}

                    </Link>
                </li> */}
      </ul>
    </div>
  );
}
