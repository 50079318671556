import React from 'react';
import Newsheader from "./NewsHeader.module.css";

export default function NewsHeader(props){
    return(
        <div className='w-full mt-10'>
            <div className={Newsheader.header}>
                <div className="flex items-center justify-center h-full text-[5vw] font-bold">Welcome back, {props.username}</div>
            </div>
        </div>
    );
}