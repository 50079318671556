import React, { useContext, useEffect } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";
import FormSubmitPopup from "./FormSubmitPopup";

function FormStep6() {
  let { stepSixData, setStepSixData } = useContext(FormDataContext);
  let blurAfterFocus = (element) => {
    let regex = element.pattern;
    let testResult = regex.test(stepSixData[`${element.stateName}`]);
    if (!testResult) {
      setStepSixData({
        ...stepSixData,
        [`${element.blurAfterFocusStateName}`]: true,
      });
    } else {
      setStepSixData({
        ...stepSixData,
        [`${element.blurAfterFocusStateName}`]: false,
      });
    }
  };

  let formElements = [
    {
      id: 1,
      type: "radio",
      fieldName: "How did you know about the recruitment?",
      stateName: "howDidYouKnowAboutRecruitment",
      options: [
        { id: 1, value: "Volunteer" },
        { id: 2, value: "Facebook" },
        { id: 3, value: "Wall Poster" },
        { id: 4, value: "Booth" },
        { id: 5, value: "Friend" },
        { id: 6, value: "LinkedIn" },
        { id: 8, value: "Other" },
      ],
      blurAfterFocusStateName: "civilWorkBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 2,
      type: "text with visability condition",
      fieldName: "What is the volunteer's name?",
      stateName: "volunteersName",
      visablityCondition: stepSixData.howDidYouKnowAboutRecruitment,
      condtionValue: "Volunteer",
      blurAfterFocusStateName: "volunteersNameBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 3,
      type: "text with visability condition",
      fieldName: "What is your friend's name?",
      stateName: "friendsName",
      visablityCondition: stepSixData.howDidYouKnowAboutRecruitment,
      condtionValue: "Friend",
      blurAfterFocusStateName: "friendsNameBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 4,
      type: "text with visability condition",
      fieldName:
        "Can you please explain more about how you knew about the recruitment?",
      stateName: "otherName",
      visablityCondition: stepSixData.howDidYouKnowAboutRecruitment,
      condtionValue: "Other",
      blurAfterFocusStateName: "otherNameBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
  ];

  return (
    <form>
      <h2 className={style.stepTitle}>Feedback</h2>
      <div>
        {formElements.map((element) => {
          return (
            <div key={element.id}>
              {element.type == "radio" ? (
                // ------------------ in case of radioButton
                <div className={style.fieldContainer}>
                  <div className={style.fieldLabel}>{element.fieldName}</div>
                  <div className={style.radioSection}>
                    {element.options.map((option) => {
                      return (
                        <label key={option.id}>
                          <input
                            className={style.radioItemInput}
                            type="radio"
                            id={option.value}
                            name={element.fieldName}
                            checked={
                              stepSixData[`${element.stateName}`] ===
                              option.value
                            }
                            value={option.value}
                            onChange={(e) => {
                              setStepSixData({
                                ...stepSixData,
                                [`${element.stateName}`]: e.target.value,
                                [`${element.blurAfterFocusStateName}`]: false,
                              });
                            }}
                          />
                          <span className={style.radioItemLabel}>
                            {option.value}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <span
                    className={
                      stepSixData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : element.type == "text with visability condition" ? (
                // ------------------ in case of text filed with visability condition
                <div
                  className={
                    element.visablityCondition == element.condtionValue
                      ? style.fieldContainer
                      : "hidden"
                  }
                >
                  <label
                    htmlFor={element.fieldName}
                    className={style.fieldLabel}
                  >
                    {element.fieldName}
                  </label>
                  <input
                    className={style.inputField}
                    type="text"
                    id={element.fieldName}
                    value={stepSixData[`${element.stateName}`]}
                    onChange={(e) => {
                      setStepSixData({
                        ...stepSixData,
                        [`${element.stateName}`]: e.target.value,
                      });
                    }}
                    pattern={element.pattern}
                    onBlur={() => blurAfterFocus(element)}
                  />
                  <span
                    className={
                      stepSixData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
      <div>
        <StepsButtons />
      </div>
      <FormSubmitPopup />
    </form>
  );
}

export default FormStep6;
