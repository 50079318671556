import React from "react";
import Lottie from "react-lottie";
export default function CustomLottie({ animation, height, width }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <Lottie
      isClickToPauseDisabled={true}
      options={defaultOptions}
      height={height}
      width={width}
    />
  );
}
