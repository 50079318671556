import * as Icon from "react-icons/hi";

const myArr = [
    {
        icon: <Icon.HiSearch />,
        title: "Search",
        path: "/"
    },
    {
        icon: <Icon.HiPhotograph size='24' />,
        title: "Image",
        path: "/feed",
    },
    {
        icon: <Icon.HiPresentationChartLine size='24'/>,
        title: "Poll / Activity",
        path: "/feed",
    },

]

export default myArr;