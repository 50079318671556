const headCells = [
  {
    id: "fullName",
    numeric: false,
    disablePadding: true,
    label: "Full Name",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "primaryCommittee",
    numeric: false,
    disablePadding: false,
    label: "Primary Committee",
  },
  {
    id: "secondaryCommitee1",
    numeric: false,
    disablePadding: false,
    label: "1st Secondary Committee",
  },
  {
    id: "secondaryCommitee2",
    numeric: false,
    disablePadding: false,
    label: "2nd Secondary Committee",
  },
  {
    id: "operations",
    numeric: false,
    disablePadding: false,
    label: "Operations",
    colspan: 2,
  },
];
export default headCells;
