import React from "react";

import { useNavigate, useParams } from "react-router-dom";

import NewsDetailsStyles from "./NewsDetailsStyles.module.css";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { collection, query, getDocs, where } from "firebase/firestore";

import * as IoIcons from "react-icons/io5";
import { selectedGridRowsCountSelector } from "@mui/x-data-grid";

function NewsDetails(props) {
  const navigate = useNavigate();
  const [applyButtonClick, setApplyButtonClicked] = useState(false);

  const params = useParams();

  const [durationtime, setduration] = useState([]);

  const [Event, setEvent] = useState([]);
  const [Desc, setDesc] = useState("");

  useEffect(() => {
    getEvent(params.id);
  }, []);

  const getEvent = async (params) => {
    const q = query(
      collection(props.db, "events"),
      where("link", "==", params)
    );
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      setEvent(doc.data());
      console.log(doc.data().starteventdate.seconds);
      setduration(
        duration(
          moment.unix(doc.data().starteventdate.seconds),
          moment.unix(doc.data().endeventdate.seconds)
        )
      );
      setDesc(doc.data().description);
    });
  };

  function duration(start, end) {
    var startTime = moment(start, "DD-MM-YYYY hh:mm:ss");
    var endTime = moment(end, "DD-MM-YYYY hh:mm:ss");
    var daydiff = endTime.diff(startTime, "days");
    var hourdiff = endTime.diff(startTime, "hours");
    var mindiff = endTime.diff(startTime, "minutes");
    while (hourdiff >= 24) {
      hourdiff = hourdiff - 24;
    }
    while (mindiff >= 60) {
      mindiff = mindiff - 60;
    }
    if (daydiff === 0 && mindiff === 0) {
      return hourdiff + " hr ";
    }

    if (mindiff === 0) {
      return daydiff + " d " + hourdiff + " hr ";
    }
    if (daydiff === 0) {
      return hourdiff + " hr " + mindiff + " min ";
    }
    return daydiff + " d " + hourdiff + " hr " + mindiff + " min ";
  }

  const Applybtn = styled(Button)({
    // minWidth: 200,
    // minHeight: 50,
    padding: "10px 30px",
    variant: "contained",
    color: Event.form === "" ? "#fff" : "#000",
    "&:hover": {
      transform: "scale(1.1)",
      backgroundColor: "#fff",
      color: "#000",
    },
    fontSize: 16,
    borderRadius: 50,

    backgroundColor: Event.form === "" ? "#121316" : "#3992FF",

    transition: "0.5s cubic-bezier(.47,1.64,.41,.8)",
  });

  const SecButton = styled(Button)({
    // minWidth: 200,
    // minHeight: 50,
    padding: "10px 30px",
    variant: "contained",
    color: "#000",
    "&:hover": {
      transform: "scale(1.1)",
      backgroundColor: "#3992FF",
      color: "#fff",
    },
    fontSize: 16,
    borderRadius: 50,
    backgroundColor: Event.form === "" ? "#fff" : "#000",
    transition: "0.5s cubic-bezier(.47,1.64,.41,.8)",
  });

  const Style = {
    backgroundImage: `linear-gradient(rgb(0,0,0,0.25), #1b1c20), url(${Event.image}) `,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top center",
    backgroundSize: "cover",
    position: "relative",
  };

  return (
    <div className={NewsDetailsStyles.body}>
      <div style={Style} className="h-screen">
        <section className="flex flex-col lg:flex-row justify-end lg:justify-between lg:items-end h-[85vh] px-10">
          <section>
            <h1 className="py-5 lg:py-0 text-6xl text-white font-bold lg:w-[50vw]">
              {Event.title}
            </h1>
          </section>
          <section className="flex flex-col space-y-5 lg:flex-row lg:space-x-5 lg:space-y-0">
            <div className={NewsDetailsStyles.applybtn}>
              <Applybtn href={Event.form} className="w-[80vw] lg:w-full">
                {Event.form === "" ? "Event is Closed" : "Apply Now"}
              </Applybtn>
            </div>
            <div className={NewsDetailsStyles.secbtn}>
              <SecButton
                className="w-[80vw] lg:w-full"
                href={Event.learnmore}
                target="_blank"
                rel="noreferrer"
              >
                Learn More
              </SecButton>
            </div>
          </section>
        </section>
      </div>
      <div className="mt-32 xl:mt-16 grid grid-cols-1 lg:grid-cols-2 place-items-end px-5 lg:px-10 py-10 gap-10">
        <div className={`${NewsDetailsStyles.title}`}>
          Event Details
          <div className={NewsDetailsStyles.description}>
            <div style={{ whiteSpace: "pre-line" }}>
              {Desc.replace(/(\\n)/g, "\u000A")}
            </div>
          </div>
        </div>
        <div className={`${NewsDetailsStyles.details}`}>
          <div>
            <span>Event Date: </span>
            <p>
              {Event.starteventdate
                ? moment
                    .unix(Event.starteventdate.seconds)
                    .format("DD MMMM YYYY, h:mm A") +
                  " to " +
                  moment
                    .unix(Event.endeventdate.seconds)
                    .format("DD MMMM YYYY, h:mm A")
                : ""}
            </p>
          </div>
          <div>
            <span>Duration: </span>
            <p>{durationtime}</p>
          </div>
          <div>
            <span>Remaining Seats: </span>
            <p>{Event.remseats}</p>
          </div>
          <div>
            <span>Price: </span>
            <p>{Event.price}</p>
          </div>
          <div>
            <span>Location: </span>
            <p>{Event.location}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsDetails;
