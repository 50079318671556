import React from "react";

const Avatar = () => {
  return (
    <div className="pl-[1rem]">
      <img
        src="https://www.whatsappimages.in/wp-content/uploads/2021/06/HD-New-Beautiful-Unique-Profile-Images-Pictures.gif"
        alt=""
        className="rounded-full  xl:left-4 lg:h-[4rem] lg:w-[4rem]   lg:relative   w-14 h-14 border-2 border-white border-solid xl:w-[7rem] xl:h-[7rem] "
      />
    </div>
  );
};

export default Avatar;
