import React from "react";
import Marquee from "react-fast-marquee";
import PartnersCard from "../../components/PartnersCard";
export default function Partners({ partners }) {
  return (
    <div className="section3 p-12">
      <h1 className="text-white text-center text-5xl xl:text-7xl mb-11 font-bold  sm:text-4xl">
        Our Partners
      </h1>
      <Marquee gradient={false} speed={50} className="h-96">
        {partners &&
          partners.map((partner, index) => {
            return (
              <PartnersCard
                key={index}
                name={partner.name}
                image={partner.image}
              />
            );
          })}
      </Marquee>
    </div>
  );
}
