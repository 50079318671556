const awards = [
  // "IEEE Day Photo Contest Award (2010) 📷",
  // "Region 8 Exemplary Student Branch Award (2012) 🙋🏻‍♀️",
  "IEEE Enterprise Award-Honorable Mention (2012) 🎖️",
  "Darrel Chong Student Activity Bronze Award (2013) 💛",
  "Region 8 Exemplary Student Branch Award (2013) 📷",
  "IEEE Student Branches International Website Contest (2013) –  First Place",
  "Region 8 Exemplary Student Branch Award (2014) 🎖️",
  "Outstanding Branch Counselor & Branch Chapter Advisor – Prof. Roushdy Abdelrassoul (2014) 🙋🏻‍♀️",
  "Darrel Chong Student Activity Silver Award (2015)",
  "Darrel Chong Student Activity Gold Award (2017)",
  "Darrel Chong Student Activity Gold Award (2018) 🎖️",
  "Darrel Chong Student Activity Gold Award (2019) 💛",
  "Region 8 Exemplary Student Branch Award (2019) 🙋🏻‍♀️",
  "Outstanding Branch Counselor & Branch Chapter Advisor – Prof. Ahmed Abdelaziz (2021) 📷 ",
  "Region 8 Exemplary Student Branch Award (2021) 🙋🏻‍♀️",
  "Darrel Chong Student Activity Bronze Award (2022) 🎖️",
  "Darrel Chong Student Activity Bronze Award II (2022) 💛",
  "IEEE Egyptian SYP Organizing Student Branch Award (2022) 📷",
  "Region 8 Exemplary Student Branch Award (2022) 🙋🏻‍♀️",
  "Region 8 Exemplary Student Branch Award (2023) 🙋🏻‍♀️",
];
export default awards;
