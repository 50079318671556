import React from "react";
import CardUI from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, CardActions } from "@mui/material";
import CardStyles from "./CardStyles.module.css";
import IconButton from "@mui/material/IconButton";
import * as AIicons from "react-icons/ai";
import { useState, useEffect } from "react";
import Grow from "@mui/material/Grow";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import Fade from "@mui/material/Fade";

function Card(props) {
  let desc = props.description;
  let like = props.likes;
  const [checked, setChecked] = useState(false);
  const [checkedpop, setCheckedpop] = useState(false);
  const [copySuccess, setCopySuccess] = useState("");
  const [open, setOpen] = useState(false);
  async function copyToClip() {
    await navigator.clipboard.writeText("https://ieeeaast.org" + props.link);
    setCopySuccess("Copied");
  }

  useEffect(() => {
    if (!checked) {
      handleChange();
      setChecked(true);
    }
  }, []);
  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const handlepopup = () => {
    setCheckedpop((prev) => !prev);
  };

  return (
    <Grow in={checked}>
      <CardUI
        className={
          props.image ? CardStyles.cardcontainer : CardStyles.cardquote
        }
        style={{ display: "flex", flexDirection: "column" }}
        variant="outlined"
      >
        <CardActionArea href={props.link}>
          <CardMedia
            component="img"
            height="140"
            image={props.image}
            alt={props.title}
          />
          <CardContent>
            <Typography
              gutterBottom
              variant="p"
              component="div"
              className={CardStyles.year}
            >
              {props.date}
            </Typography>
            <Typography
              gutterBottom
              variant="p"
              component="div"
              className={props.quote ? CardStyles.quote : CardStyles.noquote}
            >
              <img src="/Icons/lightbulb.png" alt="Quote"></img>
              {props.quote}
            </Typography>
            <Typography
              gutterBottom
              variant="p"
              component="div"
              className={CardStyles.title}
            >
              {props.title}
            </Typography>
            <Typography variant="body3" className={CardStyles.description}>
              {desc
                ? desc.split(" ").slice(0, 35).join(" ") +
                  "......, Click here to see more."
                : ""}
            </Typography>
          </CardContent>
        </CardActionArea>
        <Typography
          component="div"
          className={CardStyles.break}
          style={{ marginTop: "auto" }}
        ></Typography>
        <CardActions>
          <IconButton size="small" onClick={copyToClip}>
            <Box sx={{ width: "150%" }}>
              <Collapse in={open}>
                <Fade in={checkedpop}>
                  <Alert
                    className={CardStyles.alert}
                    severity="success"
                    color="info"
                    sx={{ mb: 3 }}
                  >
                    Copied!
                  </Alert>
                </Fade>
              </Collapse>
              <AIicons.AiOutlineShareAlt
                className="text-white"
                disabled={open}
                variant="outlined"
                onClick={() => {
                  setOpen(true);
                  handlepopup();
                  setTimeout(() => {
                    setOpen(false);
                    handlepopup();
                  }, 1000);
                }}
              />
            </Box>
          </IconButton>
        </CardActions>
      </CardUI>
    </Grow>
  );
}

export default Card;
