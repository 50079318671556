import React, { Fragment } from "react";
import {
  Animator,
  ScrollContainer,
  ScrollPage,
  MoveIn,
  Sticky,
  MoveOut,
  Fade,
  batch,
} from "react-scroll-motion";
import trophy from "../../Images/trophy 1 (1).png";
import style from "./about.module.css";
import BoardCard from "../../components/cards/boardCard";
import chairmanImg from "../../Images/omar.jpg";
import viceChairmanImg from "../../Images/ahmed.jpg";
import treasurerImg from "../../Images/essam.jpg";
// import secretaryImg from "../../Images/judy.jpg";
import counselorImg from "../../Images/Prof. Ahmed Abdelaziz - Counselor.jpg";
import CustomLottie from "../../lotties/CustomLottie";
import launch from "../../lotties/launch.json";
import awards from "../Landing Page Components/Awards";
import CommiteeCard from "../../components/cards/CommiteeCard";
import committees from "../Landing Page Components/Committees";

function About() {
  let x = 300;
  return (
    <Fragment>
      <ScrollContainer>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
            <h1
              className={`text-6xl sm:text-8xl xl:text-9xl sm:w-screen  text-center text-white font-bold `}
            >
              About Us
            </h1>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), MoveOut(0, -200))}>
            <h1 className="text-white text-6xl xl:text-9xl sm:w-screen md:text-8xl  font-bold text-center">
              Our Mission<span className={style.blue}>.</span>
            </h1>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={MoveIn(0, 1000)}>
            <span className="text-white text-6xl">Since 2002,</span>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={batch(Fade(), Sticky(), MoveIn(0, 1000))}>
            <div
              className={`${style.test} flex flex-col h-screen w-screen p-4 lg:p-32 xl:flex-row`}
            >
              <span className="text-white text-3xl sm:text-4xl lg:text-5xl text-left">
                we have been continuously helping students enhance their{" "}
                <span className={style.blue}>technical</span> and{" "}
                <span className={style.blue}>soft skills</span>{" "}
              </span>
              <div className="sm:w-9/12">
                <CustomLottie animation={launch} />
              </div>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={MoveIn(0, 1000)}>
            <div
              className={`w-screen py-32 px-5 sm:h-screen sm:flex sm:flex-col sm:justify-center bg-white drop-shadow-lg ${style.ourMission}`}
            >
              <h2 className="text-black text-2xl sm:text-left md:text-3xl xl:text-5xl sm:ml-0  md:text-left md:ml-0">
                IEEE AAST ALEXSB aims to provide{" "}
                <span className=" font-bold">undergraduates</span>,
              </h2>
              <h2 className="text-black text-2xl md:text-3xl xl:text-5xl sm:ml-0 font-bold sm:text-2xl sm:text-left md:text-left ">
                graduates,{" "}
              </h2>
              <h2 className="text-black text-2xl md:text-3xl xl:text-5xl sm:ml-0 mb-1 sm:text-2xl sm:text-left  md:text-left">
                and <span className=" font-bold">technology enthusiasts</span>{" "}
              </h2>
              <h2 className="text-black text-2xl xl:text-5xl  sm:ml-0 sm:text-left md:text-3xl md:ml-0 md:text-left">
                the skills they need to be qualified for the continuously
                evolving industry.
              </h2>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={MoveIn(0, 900)}>
            <div className="flex justify-center align-middle  h-screen">
              <h2
                className={`text-white font-bold text-6xl md:text-8xl xl:text-9xl text-center m-auto ${style.heading}`}
              >
                Our Vision<span className={style.blue}>.</span>
              </h2>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={MoveIn(0, 1000)}>
            <div
              className={`w-screen py-56 flex flex-col align-middle justify-center bg-white drop-shadow-lg ${style.ourVision}`}
            >
              <h2 className="text-black text-2xl xl:text-5xl text-center font-bold mb-11 sm:text-3xl">
                IEEE AAST offers students a variety of events
              </h2>
              <div className="flex justify-around ">
                <div className="flex flex-col  ">
                  <h2 className="text-black text-2xl xl:text-5xl mb-11 sm:text-2xl md:text-3xl">
                    sessions{" "}
                  </h2>
                  <h2 className="text-black text-2xl xl:text-5xl  mb-11 sm:text-2xl md:text-3xl">
                    workshops{" "}
                  </h2>
                </div>
                <div className="flex flex-col ">
                  <h2 className="text-black text-2xl xl:text-5xl mb-11 sm:text-2xl md:text-3xl">
                    webinars
                  </h2>
                  <h2 className="text-black text-2xl xl:text-5xl mb-11 sm:text-2xl md:text-3xl">
                    hackathons
                  </h2>
                </div>
              </div>

              <h2 className="text-black text-2xl text-center sm:text-3xl">
                {" "}
                <span className="font-bold">MEGA</span> Events
              </h2>
            </div>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <div>
            <span className="text-white text-4xl text-center ">
              <Animator animation={MoveIn(-1000, 0)}>
                {" "}
                <span className={"text-9xl " + style.blue}>.</span>{" "}
              </Animator>
              <Animator animation={MoveIn(1000, 0)}>
                {" "}
                <span className="text-9xl text-white">.</span>{" "}
              </Animator>
              <Animator animation={MoveIn(-1000, 0)}>
                {" "}
                <span className={"text-9xl " + style.blue}>.</span>{" "}
              </Animator>
              <Animator animation={MoveIn(1000, 0)}>
                {" "}
                <span className="text-9xl text-white">.</span>{" "}
              </Animator>
              <Animator animation={MoveIn(-1000, 0)}>
                {" "}
                <span className={"text-9xl " + style.blue}>.</span>{" "}
              </Animator>
            </span>
          </div>
        </ScrollPage>
        <ScrollPage>
          <Animator animation={MoveIn(0, 900)}>
            <h2
              className={`text-white font-bold text-6xl md:text-8xl xl:text-9xl text-center xs:text-center xs:w-screen xs:text-6xl ${style.heading}`}
            >
              Our Awards<span className={style.blue}>.</span>
            </h2>
          </Animator>
        </ScrollPage>
        <ScrollPage>
          <div
            className="xs:p-5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <img src={trophy} alt="" className="absolute opacity-5" /> */}
            <div className="text-white md:text-base lg:text-lg xl:text-xl space-y-6 text-sm md:space-y-3 text-center">
              {awards.map((award) => {
                x = -x;
                return <Animator animation={MoveIn(x, 0)}>{award}</Animator>;
              })}
            </div>
          </div>
        </ScrollPage>
        <ScrollPage>
          <div className="flex justify-center align-middle">
            <Animator animation={MoveIn(0, 900)}>
              <h1
                className={`text-white text-5xl lg:text-8xl xl:text-9xl text-center font-bold xs:text-center xs:w-screen xs:text-5xl sm:text-5xl md:text-7xl `}
              >
                Our Committees<span className={style.blue}>.</span>
              </h1>
            </Animator>
          </div>
        </ScrollPage>
      </ScrollContainer>

      <div
        className={`flex flex-col flex-wrap sm:flex-row text-white ml-11 text-4xl text-center justify-center items-center   ${style.commiteeContainer}`}
      >
        {committees.map((commitee) => {
          return (
            <CommiteeCard
              name={commitee.name}
              image={commitee.image}
              desc={commitee.desc}
            />
          );
        })}
      </div>

      <div
        className={`flex justify-around align-middle  flex-wrap w-screen bg-black ${style.boardContainer}`}
      >
        <h2 className="text-white font-bold text-5xl lg:text-8xl mt-56 text-center w-full mb-32 sm:text-5xl">
          Meet Our Board<span className={style.blue}>.</span>
        </h2>
        <div className="cards flex justify-around align-middle w-full flex-wrap">
          <div className="w-full ">
            <BoardCard
              name={"Prof. Ahmed Abdelaziz"}
              position="Counselor"
              image={counselorImg}
            />
          </div>
          <BoardCard
            name={"Omar Khaled"}
            position="Chairman"
            image={chairmanImg}
            email="omarkhaled@ieee.org"
            linkedInUrl="https://linkedin.com/in/omaarkhaledd"
          />
          <BoardCard
            name={"Ahmed Aboualrous"}
            position="Vice Chairman"
            image={viceChairmanImg}
            email="ahmedaboualrousofficial@ieee.org"
            linkedInUrl="https://www.linkedin.com/in/ahmed-aboualrous-72ba6724b/"
          />
          {/* <BoardCard
            name={"Judy Essam"}
            position="Secretary"
            image={secretaryImg}
            email="judy-essam@ieee.org"
            linkedInUrl="https://www.linkedin.com/in/judy-e-farouk-a92081219"
          /> */}
          <BoardCard
            name={"Mohamed Essam"}
            position="Treasurer"
            image={treasurerImg}
            email="mohamedissam@ieee.org"
            linkedInUrl="https://www.linkedin.com/in/mohamed-essam-060100265/"
          />
        </div>
      </div>
    </Fragment>
  );
}

export default About;
