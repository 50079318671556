import React from "react";
import axios from "axios";
import SetCookie from "../../Hooks/SetCookie";
import { Navigate, useNavigate } from "react-router-dom";
export class AuthServices {
  baseUrl = "https://ieeeaast-backend-ieeeaast.vercel.app/api/auth";
  async login(loginData) {
    try {
      const { data } = await axios.post(`${this.baseUrl}/login`, loginData);
      SetCookie("token", data.token);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  signup(data) {
    return axios.post(`${this.baseUrl}/register`, data);
  }
  validatePassword(passsword, cPassowrd) {
    const pattern =
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
    if (!pattern.test(passsword))
      return {
        success: false,
        message:
          "Password must include at least one upper case English letter, at least one lower case English letter, at least one digit, at least one special character, minimum eight characters",
      };
    return { success: true, message: "" };
  }
}
export default new AuthServices();
