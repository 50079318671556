import React from "react";

const ResetPassForm = () => {
  return (
    <div className="flex items-center text-center justify-between  flex-col xl:text-left xl:items-baseline xl:relative   xl:w-[62rem] xl:h-[31rem]    xl:border xl:border-[#2B2E30] xl:border-solid xl:px-[1rem] xl:py-[2rem]">
      <form className=" ">
        <div className="xl:w-[78rem]   w-[26rem]">
          <input
            className="bg-transparent placeholder:text-[#525659] text-[#fff]  rounded-3xl w-[77%] border border-[#2B2E30] font-light  text-[20px] p-4 focus:outline-[#525659]"
            type="text"
            placeholder="Current Password"
          />
        </div>
        <div className="xl:w-[78rem] w-[26rem]">
          <input
            className="bg-transparent mt-10 placeholder:text-[#525659] text-[#fff]  rounded-3xl w-[77%] border border-[#2B2E30] font-light  text-[20px] p-4 focus:outline-[#525659]"
            type="text"
            placeholder="New Password"
          />
        </div>

        <div className="xl:w-[78rem] w-[26rem]">
          <input
            className="bg-transparent placeholder:text-[#525659]  rounded-3xl w-[77%] text-[#fff]  border mt-10 border-[#2B2E30] font-light  text-[20px] p-4 focus:outline-[#525659]"
            type="text"
            placeholder="Re-write Password"
          />
        </div>
        <button className="p-2 font-semibold rounded-2xl mt-8 w-[6rem] xl:absolute xl:hover:animate-pulse bg-[#0E4D78] xl:right-[14px] xl:w-[5rem] xl:rounded-2xl text-[#fff]    bg-none   ">
          Save
        </button>
      </form>
    </div>
  );
};

export default ResetPassForm;
