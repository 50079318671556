import React from "react";
import SettingsList from "../../../components/profile/SettingsList";

import TopSetBar from "../../../components/profile/TopSetBar";

const ProfileSettings = () => {
  return (
    <div className=" lg:pl-[15px]  lg:pr-[15px]   pt-[5rem]  pr-[1px] pl-[1px] w-screen h-screen   bg-black">
      <TopSetBar></TopSetBar>
      <div className="xl:flex">
        {/* <div className="hidden xl:block xl:w-[20%] ">hello</div> */}
        <div className=" xl:w-[100%]  ">
          <SettingsList></SettingsList>
        </div>
      </div>
    </div>
  );
};

export default ProfileSettings;
