import React from "react";
import { NavLink } from "react-router-dom";

const TopSetBar = () => {
  return (
    <div className="flex pb-6 justify-between ">
        <NavLink className="text-[2rem] pl-3 text-white" to="/profile">
      <button>
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 24 24"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path  d="M21 11L6.414 11 11.707 5.707 10.293 4.293 2.586 12 10.293 19.707 11.707 18.293 6.414 13 21 13z"></path>
        </svg>
      </button>
      </NavLink>
      <h2 className="ml-[2rem] pr-3 relative top-[5px] text-white">@ZeyadElshaarawy</h2>

    </div>
  );
};

export default TopSetBar;
