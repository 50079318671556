import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Card from "../../components/cards/Card";
import NewsStyles from "./NewsStyles.module.css";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { collection, getDocs, orderBy, query } from "firebase/firestore";

import Loading from "../../components/Loading";
function News(props) {
  const [OGEvents, OGsetEvents] = useState([]);
  const [Events, setEvents] = useState([]);
  const [Desc, setDesc] = useState([]);
  const [OGDesc, setOGDesc] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);
  const employeesPerPage = 9;
  const numberOfEmployeesVistited = page * employeesPerPage;
  const totalPages = Math.ceil(Events.length / employeesPerPage);
  const changePage = ({ selected }) => {
    setPage(selected);
  };
  const selectstyle =
    "form-select lg:ml-11 appearance-none mb-5 block px-3 py-1.5 text-base font-normal text-white-700 bg-blue-600 bg-clip-padding bg-no-repeat rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none ";
  function filtertype(value) {
    if (value === "") {
      getData();
    }
    const temp2 = OGEvents;
    const temp = temp2.filter((element) => element.type === value);
    setEvents(temp);
  }

  function filtersearch(value) {
    if (value === "") {
      getData();
    }
    const temp2 = OGEvents;
    try {
      const temp = temp2.filter((element) =>
        element.title.toLowerCase().includes(value.toLowerCase())
      );
      setEvents(temp);
    } catch (error) {}
  }

  function filterdate(value) {
    if (value === "") {
      getData();
    }
    const temp2 = OGEvents;
    const temp = temp2.filter((element) => {
      var timestamp = element.date.seconds * 1000;
      var date = new Date(timestamp);
      return date.getFullYear() === value;
    });
    setEvents(temp);
  }

  async function getData() {
    try {
      // const { data } = await axios.get(
      //   "https://ieee-aast-backend.onrender.com/api/events/getAllEvents"
      // );

      await getDocs(
        query(collection(props.db, "events"), orderBy("date", "desc"))
      ).then((element) => {
        setEvents(element.docs.map((doc) => doc.data()));
        OGsetEvents(element.docs.map((doc) => doc.data()));
        setLoading(false);
      });

      // setEvents(data);
      // OGsetEvents(data);
      // setLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
    filtertype();
    filterdate();
  }, []);

  return (
    <Fragment>
      {!loading ? (
        <section>
          <div className={" px-11 xl:px-14 py-24 text-white flex"}>
            <div className="flex flex-col lg:flex-row xl:flex-row align-middle justify-between w-screen sm:flex-col ">
              <h1 className="text-5xl mb-6 font-semibold">Events</h1>
              <div className="flex flex-col lg:flex-row justify-between items-center">
                <input
                  type="search"
                  className="  form-control mb-5 relative flex-auto min-w-0 block w-full px-3 py-1.5 text-base font-normal text-white bg-gray-800 bg-clip-padding border border-solid border-black rounded transition ease-in-out m-0 focus:text-white focus:bg-gray focus:border-blue-600 focus:outline-none"
                  placeholder="Search"
                  aria-label="Search"
                  aria-describedby="button-addon2"
                  onChange={(e) => filtersearch(e.target.value)}
                />
                <div className="flex justify-center items-center m-auto  lg:ml-auto xl:ml-auto">
                  <select
                    className={`${selectstyle}`}
                    aria-label="Default select example"
                    onChange={(e) => filtertype(e.target.value)}
                  >
                    <option
                      value=""
                      className="text-gray-400  font-semibold text-sm py-2 px-0 lg:px-4 block  bg-transparent"
                    >
                      Category
                    </option>
                    <option value="Webinar">Webinar</option>
                    <option value="Session">Session</option>
                    <option value="Workshop">Workshop</option>
                    <option value="Mega Event">Mega Event</option>
                    <option value="Competition">Competition</option>
                  </select>
                  {/* <select
                    className={selectstyle}
                    aria-label="Default select example"
                    onChange={(e) => filterdate(e.target.value)}
                  >
                    <option value="" style={{ textAlign: "center" }}>
                      Year
                    </option>
                    <option value="2022">2022</option>
                    <option value="2021">2021</option>
                    <option value="2020">2020</option>
                    <option value="2019">2019</option>
                  </select> */}
                </div>
              </div>
            </div>
          </div>
          <div className={NewsStyles.container}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-wrap gap-10 justify-around items-center px-5 lg:px-10">
              {Events.slice(
                numberOfEmployeesVistited,
                numberOfEmployeesVistited + employeesPerPage
              ).map((element) => {
                return (
                  <div>
                    <Card
                      date={
                        element.date
                          ? moment
                              .unix(element.date.seconds)
                              .format("MMMM Do YYYY")
                          : ""
                      }
                      image={element.image}
                      title={element.title}
                      description={element.description.replace(/(\\n)/g, "")}
                      quote={element.quote}
                      likes={element.likes}
                      link={"/events/" + element.link}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            pageCount={totalPages}
            onPageChange={changePage}
            containerClassName={NewsStyles.navigationButtons}
            previousLinkClassName={NewsStyles.previousButton}
            nextLinkClassName={NewsStyles.nextButton}
            disabledClassName={NewsStyles.navigationDisabled}
            activeClassName={NewsStyles.navigationActive}
            marginPagesDisplayed={4}
            pageRangeDisplayed={4}
          />
        </section>
      ) : (
        <Loading />
      )}
    </Fragment>
  );
}

export default News;
