import React, { useContext, useEffect } from "react";
import style from "./FormStyle.module.css";
import FormDataContext from "../../context/FormContext";
import FormEmptyFieldsPopup from "./FormEmptyFieldsPopup";

function StepsButtons() {
  let {
    step,
    setStep,
    stepOneData,
    setStepOneData,
    stepTwoData,
    setStepTwoData,
    stepThreeData,
    setStepThreeData,
    stepFourData,
    setStepFourData,
    stepFiveData,
    setStepFiveData,
    stepSixData,
    setStepSixData,
    ignoreStepTwoHiddenFields,
    ignoreStepThreeHiddenFields,
    ignoreStepSixHiddenFields,
    stepSuccess,
    checkSuccess,
    setSubmitPopup,
    setStepperPopup,
  } = useContext(FormDataContext);

  useEffect(() => {
    checkSuccess();
  }, [
    stepOneData,
    stepTwoData,
    stepThreeData,
    stepFourData,
    stepFiveData,
    stepSixData,
  ]);

  useEffect(() => {
    ignoreStepThreeHiddenFields();
  }, [stepOneData.faculty]);

  useEffect(() => {
    ignoreStepTwoHiddenFields();
  }, [stepTwoData.didAttendEvents]);

  useEffect(() => {
    ignoreStepSixHiddenFields();
  }, [stepSixData.howDidYouKnowAboutRecruitment]);

  let stepsButtons = () => {
    if (step === 1) {
      return <div className="text-right">{nextButton()}</div>;
    } else if (step === 6) {
      return (
        <div className="flex flex-row-reverse gap-x-5 items-center">
          <span>{submitButton()}</span>
          <span>{backButton()}</span>
        </div>
      );
    } else {
      return (
        <div className="flex flex-row-reverse gap-x-5 items-center">
          <span>{nextButton()}</span>
          <span>{backButton()}</span>
        </div>
      );
    }
  };

  let nextButton = () => {
    return (
      <button
        onClick={(e) => {
          nextButtonHandler(e);
        }}
        className={style.primaryButton}
      >
        Next Step
      </button>
    );
  };

  let backButton = () => {
    return (
      <button
        onClick={(e) => {
          e.preventDefault();
          if (step === 4) {
            console.log("here");
            setStepThreeData({
              primaryCommittee: "",
              secondaryCommitee1: "",
              secondaryCommittee2: [],
            });
          }
          setStep((prev) => prev - 1);
        }}
        className={style.secondaryButton}
      >
        Back
      </button>
    );
  };

  let submitButton = () => {
    return (
      <button
        onClick={(e) => {
          submitButtonHandler(e);
        }}
        className={style.primaryButton}
      >
        Submit
      </button>
    );
  };

  let nextButtonHandler = (e) => {
    e.preventDefault();
    switch (step) {
      case 1:
        if (stepSuccess.stepOneSuccess === false) {
          setStepperPopup(true);
        } else if (stepSuccess.stepOneSuccess === true) {
          setStep((prev) => prev + 1);
        }
        break;

      case 2:
        if (stepSuccess.stepTwoSuccess === false) {
          setStepperPopup(true);
        } else if (stepSuccess.stepTwoSuccess === true) {
          setStep((prev) => prev + 1);
        }
        break;
      case 3:
        if (stepSuccess.stepThreeSuccess === false) {
          setStepperPopup(true);
        } else if (stepSuccess.stepThreeSuccess === true) {
          setStep((prev) => prev + 1);
        }
        break;
      case 4:
        if (stepSuccess.stepFourSuccess === false) {
          setStepperPopup(true);
        } else if (stepSuccess.stepFourSuccess === true) {
          setStep((prev) => prev + 1);
        }
        break;
      case 5:
        if (stepSuccess.stepFiveSuccess === false) {
          setStepperPopup(true);
        } else if (stepSuccess.stepFiveSuccess === true) {
          setStep((prev) => prev + 1);
        }
        break;
      default:
        return;
    }
  };

  let submitButtonHandler = (e) => {
    e.preventDefault();
    if (stepSuccess.stepSixSuccess === false) {
      setStepperPopup(true);
    } else if (stepSuccess.stepSixSuccess === true) {
      setSubmitPopup(true);
    }
  };

  return (
    <div className="my-8 mb-16">
      {stepsButtons()}
      <div>
        <FormEmptyFieldsPopup />
      </div>
    </div>
  );
}

export default StepsButtons;
