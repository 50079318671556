import React from "react";
import NewsNav from "./news/NewsNav";
import WOnMind from "./WOnMind";
import NewsHeader from "./news/NewsHeader";

export default function Feed(props) {
  return (
    <div
      className={
        props.close
          ? "pl-24 pr-10 transition-all duration-500 h-screen w-screen"
          : "pl-24 pr-10 lg:pl-80 transition-all duration-500 h-screen w-screen"
      }
    >
      <NewsNav pagename="Feed" profilepic={props.profilepic} />
      <NewsHeader username={props.username} />
      <WOnMind profilepic={props.profilepic} />
    </div>
  );
}
