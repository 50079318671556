import React, { Fragment } from "react";
import style from "./auth.module.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useState } from "react";
import { Alert } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import logo from "../../Images/White Overlay (2).png";
import Loading from "../../components/Loading";
import { useRef } from "react";
import { useReducer } from "react";
import tw from "tailwind-styled-components";
import axios from "axios";
import { setAuthToken } from "./SetAuthToken";
import { useNavigate } from "react-router-dom";
import SetCookie from "../../Hooks/SetCookie";
const initialState = {
  username: "",
  email: "",
  password: "",
};

const reducer = (state, action) => {
  console.log(action);
  if (action.input === "username") return { ...state, username: action.value };
  if (action.input === "email") return { ...state, email: action.value };
  if (action.input === "password") return { ...state, password: action.value };
  return { ...state };
};
function SignIn() {
  const navigate = useNavigate();
  const [state, signUpDispatch] = useReducer(reducer, initialState);
  console.log(state);
  const ref = useRef(null);
  const [signUp, setSignUp] = useState(false);
  const sectionOne = useRef(null);
  const [empty, setEmptyErr] = useState(false);
  const [status, setStatus] = useState({
    message: "",
    error: false,
  });
  const checkEmpty = (email, password) => {
    return !email || !password;
  };
  const onChange = (e) => {
    const action = {
      input: e.target.name,
      value: e.target.value,
    };
    signUpDispatch(action);
  };
  const handleSignIn = async (e) => {
    try {
      e.preventDefault();
      if (checkEmpty(state.email, state.password)) {
        setEmptyErr(true);
        return;
      }
      setEmptyErr(false);
      const response = await axios
        .post("https://ieeeaast-backend-ieeeaast.vercel.app/api/auth/login", state)
        .then((response) => {
          if (response.data.status === 400) {
            setStatus({
              message: response.data.message,
              error: true,
            });
            return;
          }

          const token = response.data.token;
          SetCookie("token", token);
          setAuthToken(token);
          navigate("/recruitment_entries");
        });
    } catch (error) {
      console.log(error);
      setStatus({
        message: "Email or password is incorrect",
        error: true,
      });
    }
  };

  const handleSignUp = async (e) => {
    try {
      e.preventDefault();
      if (checkEmpty(state.email, state.password)) {
        setEmptyErr(true);
        return;
      }
      const response = await axios
        .post("https://ieeeaast-backend-ieeeaast.vercel.app/api/auth/register", state)
        .then((response) => {
          if (response.data.status === 400) {
            setStatus({
              message: response.data.message,
              error: true,
            });
            return;
          }
          const token = response.data.token;
          SetCookie("token", token);
          setAuthToken(token);
          navigate(`/verifyyouremail/${state.email}`);
          setStatus({
            message: "",
            error: false,
          });
        });
    } catch (error) {
      console.log(error);
      setStatus({
        message: "An error occurred",
        error: true,
      });
    }
  };

  return (
    <Fragment>
      <section className={" place-items-center" + style.authSection}>
        <Form method="POST" autoComplete="off">
          <motion.div
            ref={sectionOne}
            animate={{ x: signUp ? ref.current && ref.current.offsetWidth : 0 }}
            transition={{ type: "tween", duration: 1 }}
            className="text-white  rounded flex flex-col justify-center py-28 px-3 xl:p-28 bg-transparent xl:bg-zinc-900"
          >
            <motion.h1
              animate={{ opacity: signUp ? [0, 0.5, 1] : 1 }}
              transition={{ type: "tween", duration: 1.4 }}
              className="text-white text-3xl xl:text-5xl font-bold text-left mb-11"
            >
              {signUp ? "Sign Up" : "Sign In"}
            </motion.h1>
            <motion.div
              className={signUp ? "" : "invisible"}
              animate={{ opacity: [0.3, 1] }}
              transition={{ type: "tween", duration: 2.4, delay: 0.5 }}
            >
              <label htmlFor="" className="mb-2">
                Username
              </label>
              <Input
                name="username"
                autoComplete="false"
                type="text"
                onChange={(e) => onChange(e)}
                placeholder="username"
              />
            </motion.div>
            <motion.div
              animate={{ y: !signUp ? -70 : 0 }}
              transition={{ type: "tween", duration: 0.5 }}
            >
              <label htmlFor="" className="mb-2">
                Email
              </label>
              <Input
                name="email"
                type="email"
                onChange={(e) => onChange(e)}
                placeholder="Email"
              />
            </motion.div>
            <motion.div
              animate={{ y: !signUp ? -70 : 0 }}
              transition={{ type: "tween", duration: 0.5 }}
            >
              <label htmlFor="">Password</label>
              <Input
                name="password"
                type="password"
                onChange={(e) => onChange(e)}
                placeholder="Password"
              />
            </motion.div>
            <motion.button
              type="submit"
              animate={{ y: !signUp ? -70 : 0 }}
              transition={{ type: "tween", duration: 0.5 }}
              className="rounded-lg text-white px-6 hover:scale-110 transition ease-out py-3 bg-blue-500"
              onClick={signUp ? handleSignUp : handleSignIn}
            >
              {signUp ? "Sign Up" : "Sign In"}
            </motion.button>
            <p className="text-red-700 text-base font-thin">{status.message}</p>
            <motion.p
              onClick={() => {
                setEmptyErr(false);
                setSignUp(!signUp);
                setStatus({
                  message: "",
                  error: false,
                });
              }}
              className="decoration-blue-700 mt-5 underline-offset-1 cursor-pointer hover:text-white transition ease-in text-sky-400"
            >
              {signUp ? "Already have an account?" : "Dont't have an account?"}
            </motion.p>
            <p className="text-white font-thin mt-5">
              Or continue with these social profiles
            </p>
            {/* <span className="flex justify-start">
              <button className=" w-1/12 h-1/12 mr-5 hover:text-sky-500 rounded-full hover:scale-110 mt-6 transition ease-out">
                <AiIcons.AiFillGoogleCircle className="text-5xl " />
              </button>
              <button className=" w-1/12 h-1/12 rounded-full hover:scale-110 hover:text-sky-500 mt-6 transition ease-out">
                <AiIcons.AiFillFacebook className="text-5xl " />
              </button>
            </span> */}
            <Alert
              severity="error"
              className={`${empty ? "block mt-5" : " invisible  border-4"}`}
            >
              Please fill all inputs
            </Alert>
          </motion.div>
          <motion.div
            ref={ref}
            animate={{
              x: signUp ? sectionOne && -sectionOne.current.offsetWidth : 0,
              backgroundImage: signUp
                ? "linear-gradient(to bottom , #0dccff,#4760ff)"
                : "linear-gradient(315deg, #2a2a72 0%, #009ffd 74%)",
            }}
            transition={{ type: "easeOut", duration: 1 }}
            className={
              "hidden  xl:flex rounded justify-center  flex-col shadow-lg  " +
              style.signInImage
            }
          >
            <motion.h2
              animate={{ opacity: signUp ? [0, 0.5, 1] : [0.5, 0.7, 1] }}
              transition={{ type: "tween", duration: 1.4 }}
              className="text-white text-6xl ml-5  text-center font-bold "
            >
              {signUp ? "Hello Friend!" : "welcome back!"}
            </motion.h2>
            <img src={logo} alt="" className="w-6/12 mx-auto" />
          </motion.div>
        </Form>
      </section>
    </Fragment>
  );
}
const Form = tw.form`grid grid-flow-col xl:grid-flow-row xl:grid-cols-2 relative  m-auto w-full xl:w-9/12 shadow-black shadow-xl rounded-lg text-white`;
const Input = tw.input`mb-5 py-3 bg-transparent shadow appearance-none  border border-t-0 border-l-0 border-r-0 rounded w-full px-4 col-span-3  hover:bg-white hover:text-black focus:bg-transparent focus:text-white transition ease-in leading-tight focus:outline-none focus:shadow-outline`;
export default SignIn;
