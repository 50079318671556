import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import style from "./FormStyle.module.css";

function FormConfirmSuccess() {
  const navigate = useNavigate();

  return (
    <div>
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center">
        <div className="flex items-center justify-center bg-[#252525] flex-col gap-5 py-10 px-10 text-[black] border-2 border-[#3992ff] rounded-xl">
          <p className={style.popupText}>Submission Successful</p>
          <div className="flex gap-5">
            <button
              className={style.primaryButton}
              onClick={(e) => navigate("/")}
            >
              Return to Homepage
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormConfirmSuccess;
