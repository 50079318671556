import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";

function FormStep4() {
  let { stepFourData, setStepFourData } = useContext(FormDataContext);
  let numbers = [10, 9, 8, 7, 6, 5, 4, 3, 2, 1];

  let fields = [
    {
      id: 1,
      fieldName: "IT",
      stateName: "ITExperience",
      experience: numbers,
      blurAfterFocusStateName: "ITExperienceBlurAfterFocus",
    },
    {
      id: 2,
      fieldName: "Technical",
      stateName: "technicalExperience",
      experience: numbers,
      blurAfterFocusStateName: "technicalExperienceBlurAfterFocus",
    },
    {
      id: 4,
      fieldName: "Media",
      stateName: "mediaExperience",
      experience: numbers,
      blurAfterFocusStateName: "mediaExperienceBlurAfterFocus",
    },
    {
      id: 5,
      fieldName: "Marketing",
      stateName: "marketingExperience",
      experience: numbers,
      blurAfterFocusStateName: "marketingExperienceBlurAfterFocus",
    },
    {
      id: 6,
      fieldName: "Documentation",
      stateName: "documentationExperience",
      experience: numbers,
      blurAfterFocusStateName: "documentationExperienceBlurAfterFocus",
    },
    {
      id: 7,
      fieldName: "Registration",
      stateName: "registrationExperience",
      experience: numbers,
      blurAfterFocusStateName: "registrationExperienceBlurAfterFocus",
    },
    {
      id: 8,
      fieldName: "Operations",
      stateName: "operationExperience",
      experience: numbers,
      blurAfterFocusStateName: "operationExperienceBlurAfterFocus",
    },
  ];
  let experienceInEachCommittee = () => {
    return (
      <div>
        {fields.map((field) => {
          return (
            <div key={field.id}>
              <div className={style.stepSubTitle}>{field.fieldName}</div>
              <div className={style.radioButtonsGroupContainerLabel}>
                <div>Not at all Likely</div>
                <div>Extremely Likely</div>
              </div>
              <div className={style.radioButtonsGroupContainer}>
                {field.experience.map((number) => {
                  return (
                    <label key={number} className={style.radioButtonsGroupItem}>
                      <input
                        type="radio"
                        name={`${field.fieldName}_experience`}
                        className={style.radioButtonsGroupInput}
                        value={number}
                        checked={stepFourData[`${field.stateName}`] == number}
                        onChange={(e) => {
                          setStepFourData({
                            ...stepFourData,
                            [`${field.stateName}`]: e.target.value,
                            [`${field.blurAfterFocusStateName}`]: false,
                          });
                        }}
                      />
                      <span className={style.radioButtonsGroupLabel}>
                        {number}
                      </span>
                    </label>
                  );
                })}
              </div>
              <div
                className={
                  stepFourData[`${field.blurAfterFocusStateName}`] == true
                    ? style.validationErrorMessage
                    : "hidden"
                }
              >
                This field is required
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <form>
      <h1 className={style.stepTitle}>Experience in each committee</h1>
      {experienceInEachCommittee()}
      <div>
        <StepsButtons />
      </div>
    </form>
  );
}

export default FormStep4;
