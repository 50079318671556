import React from "react";

const PersonalInfo = () => {
  return (
    <div className="flex flex-col pt-4   pl-[1rem] pr-[1rem] xl:pl-[2rem]      p-4    text-white md:h-36 ">
      <div className="font-bold md:text-center xl:text-left  pl-[1rem]">ZeyadElshaarawy</div>
      <div className="text-xs font-light italic mb-3 xl:text-left pl-[1rem] md:text-center">
        UserName
      </div>
      <div className="text-xs font-light italic mb-3 xl:text-left pl-[1rem] md:text-center">
        joined october 2021
      </div>
      <div className="flex justify-between items-baseline xl:justify-start   pl-[1rem] md:text-center">
        <p>
          <span className="text-[13px]">2</span>{" "}
          <span className="text-xs font-light italic mb-3 md:text-center xl:mr-[1rem]">
            following
          </span>{" "}
        </p>
        <p>
          <span className="text-[13px]">4</span> {""}
          <span className="text-xs font-light italic mb-3 md:text-center">
            followers
          </span>{" "}
        </p>
      </div>
    </div>
  );
};

export default PersonalInfo;
