import React, { useContext } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";

function ClosingRecruitmentPopup() {
  let { closingRecruitmentPopup, setClosingRecruitmentPopup } =
    useContext(FormDataContext);
  let clickHandler = () => {
    setClosingRecruitmentPopup(false);
  };
  return (
    <div>
      {closingRecruitmentPopup && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-10">
          <div className="flex items-center justify-center bg-[#252525] flex-col gap-5 py-7 px-10 text-[black] border-2 border-[#3992ff] rounded-xl">
            <p className={style.popupText}>
              We’re no longer accepting applicants, you can join again next year
            </p>
            <div>
              <button className={style.primaryButton} onClick={clickHandler}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ClosingRecruitmentPopup;
