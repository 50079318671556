import React from "react";
import headers from "../../constants/CSVHeaders";
import tw from "tailwind-styled-components";
const ApplicationDetails = ({ application }) => {
  if (
    application.secondaryCommitee2 &&
    application.secondaryCommitee2.length === 2
  ) {
    [application.secondaryCommitee1, application.secondaryCommitee2] =
      application.secondaryCommitee2;
  }
  return (
    <Wrapper className={`${!application.fullName ? "hidden" : "block"}`}>
      <Title>{application.fullName}'s Details</Title>
      <TableWrapper>
        <tbody>
          {headers.map((header, index) => {
            return (
              <Row
                className={`${
                  index % 2 === 0 ? "bg-zinc-900" : "bg-transparent"
                }`}
              >
                <LabelCell>{header.label}</LabelCell>
                <Cell className={`text-white `}>
                  {header.key === "secondaryCommittee2"
                    ? ` ${application["secondaryCommitee2"]}`
                    : ""}
                  {application[header.key] !== undefined
                    ? String(application[header.key])
                    : ""}
                </Cell>
              </Row>
            );
          })}
        </tbody>
      </TableWrapper>
    </Wrapper>
  );
};
const Wrapper = tw.div`flex flex-col justify-start p-11 items-center table-wrapper`;
const Title = tw.h1`text-3xl text-white text-left  w-full mb-7`;
const TableWrapper = tw.table`text-white font-thin w-full`;
const Row = tw.tr`bg-zinc-900 hover:bg-zinc-800 hover:border hover:border-white transition ease-in`;
const Cell = tw.td` py-3 px-7 shadow-lg`;
const LabelCell = tw.td`-2xl py-3 shadow-lg pl-7 font-semibold`;
export default ApplicationDetails;
