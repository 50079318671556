import React from "react";
import style from "./Article.module.css";
function Article({ title, author, desc, category, image, id }) {
  return (
    <div
      className={
        "max-w-sm text-white overflow-hidden shadow-xl hover:bg-zinc-900 rounded-lg transition ease-in drop-shadow-2xl cursor:pointer " +
        style.article
      }
    >
      <img className="w-full" src={image} alt={title} />
      <div className=" px-6 py-4 content">
        <h2 className="font-bold text-xl mb-2 ">{title}</h2>
        <p className="text-lg mb-2 ">{author}</p>
        <p className="font-thin ">{desc}</p>
        <span className=" inline-block bg-gray-200 rounded-full px-3 py-1 text-xs  font-semibold text-gray-700 mr-2 mb-2">
          #{category}
        </span>
      </div>
    </div>
  );
}

export default Article;
