import React, { useContext, useState } from "react";
import FormDataContext from "../../context/FormContext";
import style from "./FormStyle.module.css";
import StepsButtons from "./FormStepsButtons";
import tw from "tailwind-styled-components";
function FormStep1() {
  let { stepOneData, setStepOneData, stepThreeData, setStepThreeData } =
    useContext(FormDataContext);
  let didFillApplication = JSON.parse(
    localStorage.getItem("didFillApplication")
  );
  const [submitAnotherResponse, setSubmitAnotherResponse] = useState(false);
  const handleSubmitAnotherResponse = () => {
    setSubmitAnotherResponse(true);
    localStorage.setItem("didFillApplication", "false");
  };
  // useEffect(() => {
  //     resetCommitteeChoicesData();
  //     console.log("resed commited choice is triggered triggered");
  // }, [stepOneData.faculty]);

  // let resetCommitteeChoicesData = () => {
  //     if (
  //         stepOneData.faculty !==
  //             "Faculty Of Computer and Information Technology" ||
  //         stepOneData.faculty !== "Faculty Of Engineering"
  //     ) {
  //         setStepThreeData({
  //             ...stepThreeData,
  //             primaryCommittee: "",
  //             secondaryCommitte1: "",
  //             secondaryCommittee2: "",
  //         });
  //     }
  // };

  let blurAfterFocus = (element) => {
    try {
      let regex = element.pattern;
      let testResult = regex.test(stepOneData[`${element.stateName}`]);
      if (!testResult) {
        setStepOneData({
          ...stepOneData,
          [`${element.blurAfterFocusStateName}`]: true,
        });
      } else {
        setStepOneData({
          ...stepOneData,
          [`${element.blurAfterFocusStateName}`]: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  let dropDownblurAfterFocus = (element) => {
    if (stepOneData[`${element.stateName}`] == "") {
      setStepOneData({
        ...stepOneData,
        [`${element.blurAfterFocusStateName}`]: true,
      });
    } else {
      setStepOneData({
        ...stepOneData,
        [`${element.blurAfterFocusStateName}`]: false,
      });
    }
  };

  let formElementsPartOne = [
    {
      id: 1,
      type: "text",
      fieldName: "Full name",
      stateName: "fullName",
      blurAfterFocusStateName: "fullNameBlurAfterFocus",
      errorMessage:
        "Full name should be at least two words and should not include any special characters",
      pattern: /\b[a-zA-Z]+\s[a-zA-Z]+\b/,
    },
    {
      id: 2,
      type: "text",
      fieldName: "Address",
      stateName: "address",
      blurAfterFocusStateName: "addressBlurAfterFocus",
      errorMessage: "Write a valid adddress",
      pattern: /[a-z]/i,
    },
    {
      id: 3,
      type: "text",
      fieldName: "Phone number",
      stateName: "phoneNumber",
      blurAfterFocusStateName: "phoneNumberBlurAfterFocus",
      // errorMessage: "Type a valid phone number",
      // pattern: /^[0][1-9]\d{9}$|^[1-9]\d{9}$/g,
    },
    {
      id: 4,
      type: "text",
      fieldName: "Email",
      stateName: "email",
      blurAfterFocusStateName: "emailBlurAfterFocus",
      errorMessage: "Type a valid Email",
      pattern:
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
    },
  ];
  let partOne = () => {
    return (
      <div>
        {formElementsPartOne.map((element) => {
          return (
            <div key={element.id}>
              {element.type == "text" ? (
                // ------------------ in case of text filed
                <div className={style.fieldContainer}>
                  <label
                    className={style.fieldLabel}
                    htmlFor={element.fieldName}
                  >
                    {element.fieldName}
                  </label>
                  <input
                    className={style.inputField}
                    type="text"
                    id={element.fieldName}
                    value={stepOneData[`${element.stateName}`]}
                    onChange={(e) => {
                      setStepOneData({
                        ...stepOneData,
                        [`${element.stateName}`]: e.target.value,
                      });
                    }}
                    pattern={element.pattern}
                    onBlur={() => blurAfterFocus(element)}
                  />
                  <span
                    className={
                      stepOneData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  let formElementsPartTwo = [
    {
      id: 1,
      type: "text",
      fieldName: "What is your total GPA?",
      stateName: "gpa",
      blurAfterFocusStateName: "gpaBlurAfterFocus",
      errorMessage: "GPA field should contain a numerical value from 1 to 4",
      pattern: /[1-4]/,
    },
    {
      id: 2,
      type: "dropDown",
      fieldName: "What semester are you in?",
      stateName: "semester",
      options: [
        { id: 0, value: "" },
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
        { id: 11, value: 11 },
        { id: 12, value: 12 },
      ],
      blurAfterFocusStateName: "semesterBlurAfterFocus",
      errorMessage: "Choose your semester",
    },
    {
      id: 3,
      type: "dropDown",
      fieldName: "What faculty are you in?",
      stateName: "faculty",
      options: [
        { id: 0, value: "" },
        {
          id: 1,
          value: "Faculty Of Technology and Maritime Transport",
        },
        { id: 2, value: "Faculty Of Engineering" },
        { id: 3, value: "Faculty Of Pharmacy" },
        {
          id: 4,
          value: "Faculty Of Computer and Information Technology",
        },
        { id: 5, value: "Faculty Of Management and Technology" },
        {
          id: 6,
          value: "Faculty Of Logistics and International Transport",
        },
        { id: 7, value: "Faculty Of Media and Language" },
        {
          id: 8,
          value: "Faculty Of Fisheries and Aquaculture Technology",
        },
        { id: 9, value: "Faculty Of Law" },
      ],
      blurAfterFocusStateName: "facultyBlurAfterFocus",
      errorMessage: "Choose your faculty",
    },
    {
      id: 4,
      type: "text",
      fieldName: "What department are you in?",
      stateName: "department",
      blurAfterFocusStateName: "departmentBlurAfterFocus",
      errorMessage: "type a valid value",
      pattern: /[a-z]/i,
    },
  ];
  let partTwo = () => {
    return (
      <div>
        {formElementsPartTwo.map((element) => {
          return (
            <div key={element.id}>
              {element.type == "text" ? (
                // ------------------ in case of text filed
                <div className={style.fieldContainer}>
                  <label
                    className={style.fieldLabel}
                    htmlFor={element.fieldName}
                  >
                    {element.fieldName}
                  </label>
                  <input
                    className={style.inputField}
                    type="text"
                    id={element.fieldName}
                    value={stepOneData[`${element.stateName}`]}
                    onChange={(e) => {
                      setStepOneData({
                        ...stepOneData,
                        [`${element.stateName}`]: e.target.value,
                      });
                    }}
                    pattern={element.pattern}
                    onBlur={() => blurAfterFocus(element)}
                  />
                  <span
                    className={
                      stepOneData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : // ------------------ in case of dropDown
              element.type == "dropDown" ? (
                <div className={style.fieldContainer}>
                  <label className={style.fieldLabel}>
                    {element.fieldName}
                  </label>
                  <select
                    className={style.inputField}
                    value={stepOneData[`${element.stateName}`]}
                    id={element.fieldName}
                    onChange={(e) => {
                      setStepOneData({
                        ...stepOneData,
                        [`${element.stateName}`]: e.target.value,
                      });
                    }}
                    onBlur={() => dropDownblurAfterFocus(element)}
                  >
                    {element.options.map((option) => {
                      return (
                        <option
                          className={style.dropDownItems}
                          key={option.id}
                          value={option.value}
                        >
                          {option.value}
                        </option>
                      );
                    })}
                  </select>
                  <span
                    className={
                      stepOneData[`${element.blurAfterFocusStateName}`] === true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  let formElementsPartThree = [
    {
      id: 1,
      type: "radio",
      fieldName: "Did you join any civil work organization before?",
      stateName: "civilWork",
      options: [
        { id: 1, value: "Yes" },
        { id: 2, value: "No" },
      ],
      blurAfterFocusStateName: "civilWorkBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    // {
    //     id: 2,
    //     type: "textArea",
    //     fieldName: "Can you please provide the civil organizations' names that you worked for?",
    //     stateName: "organizationNames",
    //     visablityCondition: stepOneData.civilWork,
    //     condtionValue: "Yes",
    //     blurAfterFocusStateName: "organizationNamesBlurAfterFocus",
    //     errorMessage: "This field is required",
    //     pattern: /[a-z]/i,
    //   },
    {
      id: 3,
      type: "textArea",
      fieldName: "State any skills you have.",
      stateName: "skills",
      blurAfterFocusStateName: "skillsBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 4,
      type: "textArea",
      fieldName: "State your hobbies.",
      stateName: "hobbies",
      blurAfterFocusStateName: "hobbiesBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 5,
      type: "textArea",
      fieldName: "How would you describe yourself?",
      stateName: "describeYourself",
      blurAfterFocusStateName: "describeYourselfBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
    {
      id: 6,
      type: "textArea",
      fieldName:
        "What achievements have you accomplished throughout your life?",
      stateName: "achievemenets",
      blurAfterFocusStateName: "achievemenetsBlurAfterFocus",
      errorMessage: "This field is required",
      pattern: /[a-z]/i,
    },
  ];
  let partThree = () => {
    return (
      <div>
        {formElementsPartThree.map((element) => {
          return (
            <div key={element.id}>
              {element.type == "textArea" ? (
                // ------------------ in case of textArea
                <div className={style.fieldContainer}>
                  <label
                    className={style.fieldLabel}
                    htmlFor={element.fieldName}
                  >
                    {element.fieldName}
                  </label>
                  <textarea
                    className={style.inputField}
                    id={element.fieldName}
                    value={stepOneData[`${element.stateName}`]}
                    onChange={(e) => {
                      setStepOneData({
                        ...stepOneData,
                        [`${element.stateName}`]: e.target.value,
                      });
                    }}
                    onBlur={() => blurAfterFocus(element)}
                  />
                  <span
                    className={
                      stepOneData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : // ------------------ in case of radioButton
              element.type == "radio" ? (
                <div className={style.fieldContainer}>
                  <div className={style.fieldLabel}>{element.fieldName}</div>
                  <div className={style.radioSection}>
                    {element.options.map((option) => {
                      return (
                        <label key={option.id}>
                          <input
                            className={style.radioItemInput}
                            type="radio"
                            id={option.value}
                            name={element.fieldName}
                            value={option.value}
                            checked={
                              stepOneData[`${element.stateName}`] ===
                              option.value
                            }
                            onChange={(e) => {
                              setStepOneData({
                                ...stepOneData,
                                [`${element.stateName}`]: e.target.value,
                                [`${element.blurAfterFocusStateName}`]: false,
                              });
                            }}
                          />
                          <span className={style.radioItemLabel}>
                            {option.value}
                          </span>
                        </label>
                      );
                    })}
                  </div>
                  <span
                    className={
                      stepOneData[`${element.blurAfterFocusStateName}`] == true
                        ? style.validationErrorMessage
                        : "hidden"
                    }
                  >
                    {element.errorMessage}
                  </span>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  return didFillApplication && !submitAnotherResponse ? (
    <SubmitAnotherResponseText>
      You already submitted your application.
      <span
        className="underline text-blue-400 ml-3"
        onClick={() => handleSubmitAnotherResponse(true)}
      >
        Submit another response?
      </span>
    </SubmitAnotherResponseText>
  ) : (
    <form>
      <h1 className={style.stepTitle}>Introduce yourself</h1>
      <div className="flex flex-wrap md:flex-nowrap gap-0 md:gap-10">
        <div className="grow w-full md:w-fit basis-10">{partOne()}</div>
        <div className="shrink grow basis-1 w-[99.99%]">{partTwo()}</div>
      </div>
      <div className="fieldContainer">{partThree()}</div>
      <div>
        <StepsButtons />
      </div>
    </form>
  );
}
const SubmitAnotherResponseText = tw.h1`text-white font-thin`;
export default FormStep1;
