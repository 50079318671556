import * as Icon from "react-icons/hi";

const myArr = [
    {
        icon: <Icon.HiHome />,
        title: "Feed",
        path: "/feed",
    },
    {
        icon: <Icon.HiViewList />,
        title: "Your Committees' Posts",
        path: "/yourcommittees-posts",
    },
    {
        icon: <Icon.HiOutlineCollection />,
        title: "Your Committees",
        path: "/yourcommittees",
    },
    {
        icon: <Icon.HiClipboardCheck />,
        title: "Tasks",
        path: "/tasks",
    },
    {
        icon: <Icon.HiPencilAlt />,
        title: "Forms",
        path: "/forms",
    },
    {
        icon: <Icon.HiMail />,
        title: "Mail",
        path: "/mail",
    },
    {
        icon: <Icon.HiCalendar />,
        title: "Calendar",
        path: "/calendar",
    },
    {
        icon: <Icon.HiUserGroup />,
        title: "Users",
        path: "/users",
    },
    {
        icon: <Icon.HiChartPie />,
        title: "Analytics",
        path: "/analytics",
    },
    {
        icon: <Icon.HiBookmark />,
        title: "Bookmarks",
        path: "/bookmarks",
    },
    {
        icon: <Icon.HiArchive />,
        title: "Inventory",
        path: "/Inventory",
    },

]

export default myArr;