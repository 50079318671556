import React from "react";
import ProfileList from "../../components/profile/ProfileList";
import Sidebar from "../../components/Sidebar";

const UserProfile = () => {
  return (
    <div className="   pt-[5rem]  pr-[1px] pl-[1px] flex justify-center items-center  bg-black">
      <Sidebar />
      <ProfileList></ProfileList>
    </div>
  );
};

export default UserProfile;
