import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
export default function EmailVerification() {
  const navigate = useNavigate();
  const [status, setStatus] = useState({ message: "Loading", error: false });
  const { destination } = useParams();
  const verify = async () => {
    try {
      const response = await axios.get(
        `https://ieeeaast-backend-ieeeaast.vercel.app/api/auth/verifyemail/${destination}`
      );
      if (response.status === 400)
        setStatus({
          message: "Email not found",
          error: true,
        });
      else
        setStatus({
          message: "Your email is now verified ✔",
          error: false,
        });
    } catch (error) {
      console.log(error);
      setStatus({
        message: "Server Error",
        error: true,
      });
    }
  };
  useEffect(() => {
    verify();
  }, []);
  return (
    <div className="h-screen  flex justify-center items-center text-white">
      <div className="shadow-2xl border p-32 text-2xl rounded border-zinc-400">
        {status.message}
        <GoToLoginPageButton
          onClick={() => navigate("/signin")}
          className={`${status.error ? "hidden" : "block"}`}
          error={status.error}
        >
          Go To Login Page
        </GoToLoginPageButton>
      </div>
    </div>
  );
}
const GoToLoginPageButton = tw.button`px-4 rounded-md m-auto mt-8 py-2 bg-blue-500 text-white `;
