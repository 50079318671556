import React from "react";
import { useParams } from "react-router-dom";
import tw from "tailwind-styled-components";
export default function VerifyYourEmailPage() {
  const { email } = useParams();
  return (
    <Wrapper>
      {" "}
      <p>
        We've send an email to {email}. Verify your email address to activate
        your account
      </p>{" "}
    </Wrapper>
  );
}
const Wrapper = tw.div`h-screen flex justify-center align-start p-32 text-white text-2xl`;
