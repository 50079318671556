import React from "react";
import FormEntries from "../components/form/FormEntries";

function Forms() {
  return (
    <div>
      <FormEntries />
    </div>
  );
}

export default Forms;
