import React from "react";
import Skill from "./Skill";
import { useState } from "react";
import SkillsWindow from "./SkillsWindow";
import { PencilIcon } from "@heroicons/react/24/solid";
import { PlusIcon } from "@heroicons/react/24/solid";

const Skills = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="  pt-4 flex flex-col    max-h-full      ">
      <div className="flex justify-between items-center  ">
        <h4 className=" min-[375px]:mr-[231px] ml-3    text-white">
          Skills
        </h4>
        <div className="flex items-center justify-between mr-4 md:mr-8 xl:mr-8">
          <PlusIcon className="lg:w-5 w-4 md:w-[1.5rem] mr-8 text-[#6A6A6A]  " />
          <PencilIcon className="lg:w-5 w-4 md:w-[1.5rem] text-[#6A6A6A]  "></PencilIcon>
        </div>
      </div>
      <div className="flex flex-col items-start">
        <Skill></Skill>
        <Skill></Skill>
        <Skill></Skill>
      </div>
      <button
        onClick={() => setOpenModal(true)}
        className="text-white text-sm  p-2 w-[11rem] relative left-[26%] md:left-[40%] lg:left-[45%] rounded-full border-2 border-gray border-solid mt-5"
      >
        Show more
      </button>
      <SkillsWindow
        open={openModal}
        onClose={() => setOpenModal(false)}
      ></SkillsWindow>
    </div>
  );
};

export default Skills;
